import { useState, useEffect } from 'react';
import { axiosClient } from '../utils/axiosClient';

/**
 * Custom hook for fetching data from an API endpoint.
 * 
 * @param {string} url - The URL to fetch data from.
 * @returns {Object} An object containing:
 *   - `data`: The fetched data or `null` if the request hasn't completed.
 *   - `isLoading`: A `boolean` indicating whether the request is in progress.
 *   - `error`: An error message if the request fails, or `null` if there is no error.
 *   - `refresh`: A function to manually trigger a re-fetch of the data.
 * 
 * @example
 * const { data, isLoading, error, refresh } = useFetch('https://api.example.com/data');
 * 
 * if (isLoading) {
 *   return <p>Loading...</p>;
 * }
 * 
 * if (error) {
 *   return <p>Error: {error}</p>;
 * }
 * 
 * return (
 *   <div>
 *     <pre>{JSON.stringify(data, null, 2)}</pre>
 *     <button onClick={refresh}>Refresh</button>
 *   </div>
 * );
 */

const useFetch = (url) => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		getData();
	}, [url]);

	const getData = () => {
		console.log("fetch")
		setIsLoading(true);
		axiosClient()
			.get(url)
			.then((response) => {
				setIsLoading(false);
				setError(null);
				setData(response.data);
			})
			.catch((err) => {
				setIsLoading(false);
				setError(err.message || 'Could not fetch the data for that resource');
			});
	};

	return {
		data, isLoading, error, refresh: getData 
	};
};

export default useFetch;
