import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { UserDataFunction } from '../../../../../../Redux/Action/userAction';
import { email } from '../../../../../../utils/axiosClient';
import ScQuantum from './components/ScQuantumComponent';

function SouthCarolinaQuantum() {
	const { userDetails } = useSelector((state) => state.userReducer);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (email) {
			dispatch(UserDataFunction(email));
		}
	}, []);

	useEffect(() => {
		// if user tries to access the page without login in first, prompt a SWAL and redirect to the login page
		if (!email) {
			Swal.fire({
				allowOutsideClick: false,
				confirmButtonColor: 'var(--brand-color-1)',
				title: 'You need to sign in to access this page.',
				icon: 'info'
			}).then((result) => {
				if (result.value) {
					navigate('/');
				}
			});
		}
	}, []);

	useEffect(() => {
		if (Object.keys(userDetails).length === 0) {
			UserDataFunction();
		}
	}, []);

	return (
		<Grid container sx={{ justifyContent: 'space-between' }}>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				sx={{
					paddingTop: '1.5rem!important'
				}}
			>
				<ScQuantum />
			</Grid>
		</Grid>
	);
}

export default SouthCarolinaQuantum