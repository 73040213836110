/**
 * @fileoverviewThis file contains the implementation of the LoginModal component.
 * It handles the login functionality for the user on the qBraid platform.
 * The component includes a form for entering the user's email and password,
 * as well as options for signing in with Google.
 * The component also handles partner authentication and redirects based on the user's actions.
 * @description The LoginModal component includes the following functionality:
 * - Rendering the login form with email and password fields
 * - Handling form submission and dispatching login actions
 * - Handling partner authentication and redirecting based on user actions
 * - Displaying a modal for successful login or error messages
 * - Toggling password visibility
 * - Validating form inputs using Yup schema
 * - Handling cookies for existing user sessions
 * - Reloading the page after successful login
 * - Dispatching actions for user data and IP verification
 * - Displaying a snack bar for login status messages
 * - Handling Google sign-in functionality
 * @todo
 * Refactor the component to improve code readability and maintainability
 * Improve error handling and error messages
 * Add support for other social media sign-in options
 * Add support for multi-factor authentication
 * @copyright (C) 2024 qBraid Development Team
 */

import { useState } from 'react';
import Cookies from 'universal-cookie';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
	Checkbox,
	Dialog,
	DialogContent,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	useMediaQuery,
	useTheme
} from '@mui/material';
import * as yup from 'yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ModalDesc from '../modal-desc';
import googleimg from '../../../assets/images/google.png';
import {
	UserDataFunction,
	UserLoginFunction,
	UserLogoutFunction,
	validateUser,
	verifyUserIpAction
} from '../../../Redux/Action/userAction';
import { email } from '../../../utils/axiosClient';
import { useQuery } from '../../../hooks/useQuery';
import { getPartnerName } from '../../../utils/utilityFunction';
import { currentDomain } from '../../../utils/domainBasedInfo/currentDomain';
import './index.css';

const HASHEDPATH = 'hashed_path';

/**
 *
 * The LoginModal component includes the following functionality:
 * @todo
 * Refactor the component to improve code readability and maintainability
 * Improve error handling and error messages
 * Add support for other social media sign-in options
 * Add support for multi-factor authentication
 *
 * @param {Object} props - The props object containing the following properties:
 *   - openLogin: A boolean indicating whether the login modal is open or not
 *   - handleCloseLogin: A function to handle closing the login modal
 *   - handleSignUp: A function to handle signing up
 *   - handleResetPass: A function to handle resetting the password
 *   - handleXanaduLogin: A function to handle Xanadu login
 *   - setOpenLogin: A function to set the open state of the login modal
 *   - setOpenSnack: A function to set the open state of the snack bar
 *   - setSnackMessage: A function to set the message of the snack bar
 *   - setSnackColor: A function to set the color of the snack bar
 *   - partnerAuth: A boolean indicating whether the login is for a partner authentication or not
 *   - userEmail: The email of the user
 *
 * @returns {JSX.Element} The LoginModal component
 *
 * @throws {Error} If there is an error during the login process
 */
export default function LoginModal({
	openLogin,
	handleCloseLogin,
	handleSignUp,
	handleResetPass,
	handleXanaduLogin,
	setOpenLogin,
	setOpenSnack,
	setSnackMessage,
	setSnackColor,
	partnerAuth,
	userEmail
}) {
	const query = useQuery();
	const muiTheme = useTheme();
	const dispatch = useDispatch();
	const [showPassword, setShowPassword] = useState(false);
	const showPasswordHandler = () => setShowPassword(!showPassword);
	const { loading, userToken } = useSelector((state) => state.userReducer);
	const validationSchema = yup.object({
		email: yup
			.string('Enter your email')
			.matches(
				/^[a-zA-Z0-9@._-]+$/,
				'An email cannot contain white spaces and special characters'
			)
			.email('Enter a valid email')
			.required('Email is required'),
		password: yup
			.string('Enter your password')
			.min(8, 'Password is required')
			.required('Password is required')
	});

	const handleQbraidLogin = async (credentials) => {
		// dispatch returns a promise, so yes it does serve a purpose (probably a code smell)
		// check for cookies
		const cookies = new Cookies();
		const existingEmail = cookies.get('EMAIL');
		const existingRefreshToken = cookies.get('REFRESH');
		if (existingEmail && existingRefreshToken) {
			// compare the existing email and login credential email
			if (existingEmail.toLowerCase() !== credentials.email.toLowerCase()) {
				// if email mismatch logout and continue login
				dispatch(UserLogoutFunction());
			} else {
				// if email matches, then compare the user session
				const isMatching = await dispatch(validateUser(credentials.email));
				if (isMatching) {
					// if user session matches, reload
					setSnackMessage('Login Successful');
					window.location.reload();
					return;
				}
				// if session mismatches, logout
				dispatch(UserLogoutFunction());
			}
		}
		// login with credentials
		const res = await dispatch(UserLoginFunction(credentials));
		console.log('res', res);
		if (res?.nextStep?.signInStep === 'DONE') {
			dispatch(UserDataFunction(email));
			setOpenLogin(false);
			setSnackColor(false);
			setOpenSnack(!loading);
			setSnackMessage('Login Successful');
			window.location.reload();
		} else {
			setOpenLogin(true);
			setSnackColor(true);
			setOpenSnack(!loading);
			// the snack bar is getting triggered here
			setSnackMessage(res.payload);
			dispatch(UserLogoutFunction(false));
		}
	};

	const handlePartnerLogin = async (credentials) => {
		try {
			const res = await dispatch(UserLoginFunction(credentials));
			if (res?.nextStep?.signInStep === 'DONE') {
				setSnackMessage('Login Successful');
				setOpenSnack(true);
				setSnackColor(false);
				const response = await dispatch(
					verifyUserIpAction({
						_email: credentials.email,
						partnerName: getPartnerName(window.location.pathname),
						hashedPath: query.get(HASHEDPATH),
						userToken: userToken
					})
				);
				if (response) {
					if (response?.status === 200) {
						setSnackMessage('OTP sent to email');
						handleXanaduLogin(credentials.email);
						setOpenLogin(false);
						setSnackColor(false);
					} else if (response?.status === 201) {
						window.location.href = response.data?.redirectUrl;
					} else {
						setOpenLogin(true);
						setSnackColor(true);
						setOpenSnack(true);
						setSnackMessage('Something went wrong!');
						// dispatch(UserLogoutFunction()).then(() => window.location.reload());
					}
				}
			} else {
				setOpenLogin(true);
				setSnackColor(true);
				setOpenSnack(!loading);
				setSnackMessage(res.payload);
			}
		} catch (err) {
			setSnackMessage('Error Login in');
			setSnackColor(true);
			setOpenSnack(true);
		}
	};

	const formik = useFormik({
		initialValues: {
			email: userEmail || '',
			password: ''
			// keepsigned: false,
		},
		validationSchema,
		onSubmit: (credentials) =>
			partnerAuth
				? handlePartnerLogin(credentials)
				: handleQbraidLogin(credentials)
	});

	const handleGoogleLogin = async () => {
		if (partnerAuth) {
			localStorage.setItem(HASHEDPATH, query.get(HASHEDPATH));
		}
		dispatch(UserLoginFunction('Google'));
	};

	return (
		<Dialog
			className={partnerAuth ? 'xanadu-login-modal' : 'login-modal'}
			open={openLogin}
			maxWidth="fitContent"
			onClose={handleCloseLogin}
			fullScreen={useMediaQuery(muiTheme.breakpoints.down('sm'))}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<DialogContent sx={{ p: 0, maxWidth: 800 }}>
				<form onSubmit={formik.handleSubmit}>
					<Grid container className="main-grid" justifyContent="center">
						<Grid
							item
							lg={5}
							xs={0}
							justifyContent="center"
							className="imgGrid"
							sx={{
								display: {
									xs: 'none',
									sm: 'none',
									md: 'none',
									lg: 'flex'
								}
							}}
						>
							<ModalDesc
								title={`Welcome to ${
									partnerAuth ? 'QHack' : currentDomain.title
								}`}
								desc={
									partnerAuth
										? 'QHack is a world-renowned annual quantum hackathon that takes your education to the next level'
										: currentDomain.loginModal.description
								}
							/>
						</Grid>
						<Grid
							item
							container
							lg={7}
							md={12}
							sm={12}
							xs={12}
							className="formGrid"
						>
							<Grid item container xs={12} justifyContent="center">
								<Grid container item xs={12}>
									<Button
										variant="outlined"
										id="googleSignInButton"
										sx={{
											color: 'inherit',
											borderColor: '#95999D',
											fontSize: '14px',
											display: 'flex',
											gap: 2,
											fontFamily: partnerAuth
												? 'Righteous !important'
												: 'Inter !important'
										}}
										fullWidth
										onClick={handleGoogleLogin}
										className="google-button"
									>
										<img
											src={googleimg}
											alt="logo"
											width="28px"
											height="28px"
										/>
										Sign in with Google
									</Button>
								</Grid>
								<Grid container item xs={12}>
									{/* <Typography className="sec-text">Or</Typography> */}
								</Grid>
								<Grid container item xs={12}>
									<TextField
										className="textfiledMargin"
										label="Email Address"
										name="email"
										fullWidth
										value={formik.values.email}
										onChange={formik.handleChange}
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={formik.touched.email && formik.errors.email}
										InputLabelProps={{
											style: {
												color: muiTheme.palette.text.auth.signup100,
												fontSize: '14px',
												fontFamily: partnerAuth
													? 'Righteous !important'
													: 'Inter !important'
											}
										}}
										sx={{
											fontFamily: partnerAuth
												? 'Righteous !important'
												: 'Inter !important'
										}}
										cy-data="email"
									/>
								</Grid>
								<Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
									<TextField
										className="textfiledMargin"
										label="Password"
										fullWidth
										type={showPassword ? 'text' : 'password'}
										name="password"
										value={formik.values.password}
										onChange={formik.handleChange}
										error={
											formik.touched.password && Boolean(formik.errors.password)
										}
										helperText={
											formik.touched.password && formik.errors.password
										}
										InputLabelProps={{
											style: {
												color: muiTheme.palette.text.auth.signup100,
												fontSize: '14px',
												fontFamily: partnerAuth
													? 'Righteous !important'
													: 'Inter !important'
											}
										}}
										sx={{
											fontFamily: partnerAuth
												? 'Righteous !important'
												: 'Inter !important'
										}}
										cy-data="password"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton onClick={showPasswordHandler}>
														{showPassword ? (
															<VisibilityOffIcon />
														) : (
															<VisibilityIcon />
														)}
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid
									sx={{
										display: 'flex',
										justifyContent: 'start!important',
										alignItems: 'center',
										marginBottom: '1rem'
									}}
									container
									item
									xs={12}
								>
									<Checkbox
										color="primary"
										sx={(theme) => ({
											'& .MuiSvgIcon-root': { fontSize: 26 },
											paddingLeft: '0px!important',
											marginLeft: '-3px!important',
											color: theme.palette.grey[500],
											boxSizing: 'border-box',
											borderRadius: '5px'
										})}
									/>
									<Typography
										className="keep-text"
										sx={{
											fontFamily: partnerAuth
												? 'Righteous !important'
												: 'Inter !important'
										}}
									>
										keep me signed in.
									</Typography>
								</Grid>
							</Grid>
							<Grid container item xs={12}>
								<Button
									variant="contained"
									size="large"
									className={
										partnerAuth ? 'xanadu-login-button' : 'login_button'
									}
									type="submit"
									value="submit"
									cy-data="submit"
									fullWidth
									sx={{
										height: 60,
										fontFamily: partnerAuth
											? 'Righteous !important'
											: 'Inter !important'
									}}
								>
									{loading ? (
										<CircularProgress size={20} style={{ color: '#fff' }} />
									) : (
										'Login'
									)}
								</Button>
								<Button
									sx={{
										marginBottom: '1em',
										marginTop: '1em',
										color: muiTheme.palette.text.auth.login100,
										fontSize: '12px',
										'&:hover': {
											backgroundColor: 'rgba(133, 19, 247, 0.10)',
											boxShadow: ' 0px 18px 35px rgba(152, 64, 210, 0.15)'
										},
										fontFamily: partnerAuth
											? 'Righteous !important'
											: 'Inter !important'
									}}
									fullWidth
									onClick={handleResetPass}
									className="link-style"
								>
									Forgot Password?
								</Button>
							</Grid>

							<Grid container item xs={12} className="login_new">
								<Typography
									textAlign="center"
									sx={{
										fontFamily: partnerAuth
											? 'Righteous !important'
											: 'Inter !important'
									}}
								>
									New to {currentDomain.title}?{' '}
									<Button
										sx={{
											textAlign: 'center',
											color: muiTheme.palette.text.auth.login100,
											fontSize: '12px',
											'&:hover': {
												backgroundColor: 'rgba(133, 19, 247, 0.10)',
												boxShadow: ' 0px 18px 35px rgba(152, 64, 210, 0.15)'
											},
											fontFamily: partnerAuth
												? 'Righteous !important'
												: 'Inter !important'
										}}
										onClick={handleSignUp}
										className="link-style"
									>
										Create An Account
									</Button>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
}
