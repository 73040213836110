import { format } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend
} from 'recharts';

const fixDate = (updatedAt) => {
	const date = new Date(updatedAt);
	const month = format(date, 'MMM');
	const day = date.getDate();
	const time = format(date, 'HH:mm');
	return `${month} ${day}, ${time}`;
};

function ProgressGraph({ tableRows, selectedRow }) {
	const [graphData, setGraphData] = useState([]);
	const [tableSlice, setTableSlice] = useState([]);

	function convertToAcceptableGraphData(teams) {
		const transformed = [];
		teams.slice(0, 9).forEach((team) => {
			const { teamName, submissions } = team;
			submissions.forEach((submission) => {
				const { updatedAt, score } = submission;
				const formatedDate = fixDate(updatedAt);

				// Check if there is already an entry in the transformed array with the same updatedAt time
				const existingEntry = transformed.find(
					(item) => item.updatedAt === formatedDate
				);

				if (existingEntry) {
					// If an entry with the same updatedAt is found, add the team score to it
					existingEntry[teamName] = score;
				} else {
					// If no entry with the same updatedAt is found, create a new entry
					transformed.push({
						updatedAt: formatedDate,
						[teamName]: score
					});
				}
			});
		});

		// Sort the array by updatedAt from old to latest
		transformed.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));

		return transformed;
	}

	const highlightSelectedData = (tableData, selected) => {
		if (_.isEmpty(selected)) {
			return tableData.map((row) => ({
				...row,
				lineColor: `hsl(${row.lineColor}, 80%, 50%)`
			}));
		}

		return tableData.map((row) => {
			if (row.id === selected.id) {
				return { ...row, lineColor: `hsl(${row.lineColor}, 80%, 40%)` };
			}
			return {
				...row,
				lineColor: `hsl(${row.lineColor}, 20%, 80%)`
			};
		});
	};

	useEffect(() => {
		if (tableRows.length) {
			setGraphData(convertToAcceptableGraphData(tableRows));
			setTableSlice(highlightSelectedData(tableRows.slice(0, 9), selectedRow));
		}
	}, [tableRows, selectedRow]);

	return (
		<ResponsiveContainer width="100%" minHeight={500}>
			<LineChart
				width={500}
				height={300}
				data={graphData}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5
				}}
			>
				{/* <CartesianGrid strokeDasharray="3 3" /> */}
				<XAxis dataKey="updatedAt" style={{ fontSize: 14 }} />
				<YAxis style={{ fontSize: 14 }} />
				<Tooltip
					labelStyle={{
						color: '#444',
						fontSize: '12px',
						fontWeight: 'bold',
						textTransform: 'uppercase'
					}}
					contentStyle={{
						padding: 16,
						backgroundColor: '#ffffff90',
						backdropFilter: 'blur(10px)',
						borderRadius: '8px',
						border: '1px solid rgba(255, 255, 255, 0.75)'
					}}
					itemStyle={{
						paddingBlock: 8,
						fontSize: '12px',
						textShadow: '0px 1px 1px rgba(0,0,0,0.3)'
					}}
				/>
				<Legend wrapperStyle={{ fontSize: '14px' }} />
				{tableSlice.map((item) => (
					<Line
						key={item?.id}
						connectNulls
						type="monotone"
						dataKey={item?.teamName}
						stroke={item.lineColor}
						strokeWidth={1}
					/>
				))}
			</LineChart>
		</ResponsiveContainer>
	);
}

export default ProgressGraph;
