import { useEffect } from 'react';

/**
 * A custom React hook that listens for errors related to ResizeObserver and prevents them from causing an infinite loop.
 *
 * @function useResizeObserverError
 * @returns {undefined} This function does not return any value.
 *
 * @example
 * import { useResizeObserverError } from './useResizeObserverError';
 *
 * function MyComponent() {
 *   useResizeObserverError();
 *   // ... rest of your component code
 * }
 */
export function useResizeObserverError() {
	useEffect(() => {
		/**
		 * Error handler function that checks if the error message is related to ResizeObserver.
		 * If it is, the function prevents the default and propagation of the error.
		 *
		 * @param {ErrorEvent} event - The error event object.
		 */
		const errorHandler = (event) => {
			if (
				event.message ===
				'ResizeObserver loop completed with undelivered notifications.'
			) {
				event.preventDefault();
				event.stopPropagation();
			}
		};

		// Add the error handler to the window's error event listener
		window.addEventListener('error', errorHandler);

		// Remove the error handler from the window's error event listener when the component is unmounted
		return () => {
			window.removeEventListener('error', errorHandler);
		};
	}, []);
}
