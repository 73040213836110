/* eslint-disable react/jsx-indent */
/**
 * @fileoverview This file contains the code for the JobTableComponent, which is a React component that displays a table of jobs.
 * It includes functionality for filtering jobs based on their status, handling tab changes, and rendering the table rows.
 * @description The JobTableComponent includes the following features:
 * - Displaying a table of jobs with columns for date, device ID, provider, status, and actions
 * - Filtering jobs based on their status (All, In Progress, Completed)
 * - Handling tab changes to update the displayed jobs
 * - Rendering table rows for each job based on the selected tab and job status
 * - Showing a loading skeleton when jobs are being fetched
 * - Handling fullscreen toggle for the jobs list
 * @param {Object} props - The component props
 * @param {function} props.snackbar - The function to show a snackbar notification
 * @param {boolean} props.listFullscreen - The flag indicating whether the jobs list is in fullscreen mode
 * @param {function} props.handleFullscreenToggle - The function to handle the fullscreen toggle
 * @returns {JSX.Element} The JobTableComponent JSX element
 * @todo Add tests for the JobTableComponent functionality
 * @copyright (C) 2024 qBraid Development Team
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import {
	Box,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Skeleton,
	IconButton,
	Tooltip,
	Zoom,
	Grid,
	Typography,
	MenuItem,
	Menu
} from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridOnIcon from '@mui/icons-material/GridOn';
import { TabContext, TabList } from '@mui/lab';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Row from '../QuantumRowComponent';
import { sendRGAEvent } from '../../utils/googleAnalytics';
import { UserJobsFunction } from '../../Redux/Action/userJobsAction';
import { QuantumDevicesFunction } from '../../Redux/Action/quantumDevicesAction';
import { email } from '../../utils/axiosClient';

/**
 * The JobTableComponent displays a table of jobs with columns for date, device ID, provider, status, and actions.
 * It includes functionality for filtering jobs based on their status, handling tab changes, and rendering the table rows.
 * @param {Object} props - The component props
 * @param {function} props.snackbar - The function to show a snackbar notification
 * @param {boolean} props.listFullscreen - The flag indicating whether the jobs list is in fullscreen mode
 * @param {function} props.handleFullscreenToggle - The function to handle the fullscreen toggle
 * @param {string} props.quantumType - The string used to identify the rendering details
 * @returns {JSX.Element} The JobTableComponent JSX element
 * @todo Add tests for the JobTableComponent functionality
 * @example
 * <JobTableComponent
 *  snackbar={showSnackbar}
 * listFullscreen={islistFullscreen}
 * handleFullscreenToggle={handlelistFullscreenToggle}
 * />
 */
export default function QuantumTableComponent({
	snackbar,
	listFullscreen,
	handleFullscreenToggle,
	quantumType // jobs/devices
}) {
	const dispatch = useDispatch();
	const [quantumArray, setQuantumArray] = useState([]);
	const [deviceGridView, setDeviceGridView] = useState(false);
	const [tableTab, setTableTab] = useState('All');

	const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
	const openMenu = Boolean(anchorMenuEl);
	const handleMenuClick = (event) => {
		setAnchorMenuEl(event.currentTarget);
	};
	const handleMenuClose = (option) => {
		if (option) setTableTab(option);
		setAnchorMenuEl(null);
	};

	const handleChangeTableTabs = (event, newValue) => {
		setTableTab(newValue);
	};
	const handleGridViewChange = () => {
		setDeviceGridView((pre) => !pre);
	};
	const {
		isLoading: isJobsLoading,
		userJobsArray,
		success: jobsSuccess
	} = useSelector((state) => state.userJobsReducer);
	const {
		isLoading: isDeviceLoading,
		quantumDevicesArray,
		success: deviceSuccess
	} = useSelector((state) => state.quantumDeviceReducer);
	useEffect(() => {
		if (email) {
			switch (quantumType) {
				case 'devices':
					dispatch(QuantumDevicesFunction());
					break;

				default:
					dispatch(UserJobsFunction());
					break;
			}
		}
	}, []);
	useEffect(() => {
		switch (quantumType) {
			case 'devices':
				setQuantumArray(quantumDevicesArray);
				break;
			default:
				setQuantumArray(userJobsArray);
				break;
		}
	}, [userJobsArray, quantumDevicesArray]);

	const getQuantumTable = () => (
		<TabContext value={tableTab}>
			<Box
				cy-data="jobs-card"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box
					sx={(theme) => ({
						display: 'none',
						[theme.breakpoints.down('md')]: {
							display: quantumType === 'devices' && 'block'
						}
					})}
				>
					<IconButton
						aria-label="more"
						id="long-button"
						aria-controls={openMenu ? 'long-menu' : undefined}
						aria-expanded={openMenu ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleMenuClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{
							'aria-labelledby': 'long-button'
						}}
						anchorEl={anchorMenuEl}
						open={openMenu}
						onClose={handleMenuClose}
						PaperProps={{
							style: {
								width: '20ch'
							}
						}}
					>
						{['All', 'Simulator', 'QPU', 'Qiskit', 'Braket'].map((option) => (
							<MenuItem
								key={option}
								selected={option === 'Pyxis'}
								onClick={() => handleMenuClose(option)}
							>
								{option}
							</MenuItem>
						))}
					</Menu>
				</Box>
				<TabList
					onChange={handleChangeTableTabs}
					aria-label="courses section"
					sx={(theme) => ({
						[theme.breakpoints.down('md')]: {
							display: quantumType === 'devices' && 'none'
						},
						'& .MuiTabs-root': {
							minHeight: { lg: 'auto' }
						},
						'& .MuiButtonBase-root': {
							background: 'var(--bg-clr3)',
							borderRadius: '5px',
							color: 'var(--alt-font-clr2)',
							padding: '8px 13px',
							fontSize: '13px',
							fontStyle: 'normal',
							fontWeight: '600',
							lineHeight: '16px',
							textTransform: 'inherit',
							minHeight: '12px',
							minWidth: '12px',
							maxHeight: '32px',
							marginRight: '8px',
							letterSpacing: '0em'
						},
						'& .Mui-selected': {
							background: theme.palette.primary.main,
							color: '#ffffff !important'
						},
						'& .MuiTabs-indicator': {
							display: 'none'
						}
					})}
				>
					<Tab
						onClick={() => {
							sendRGAEvent('Course All', 'Tab Click');
						}}
						label="All"
						value="All"
					/>
					{quantumType === 'jobs' &&
						['In Progress', 'Completed'].map((label, index) => (
							<Tab
								// eslint-disable-next-line react/no-array-index-key
								key={`jobs tab ${index}`}
								onClick={() => {
									sendRGAEvent(`Jobs ${label}`, 'Tab Click');
								}}
								label={label}
								value={label}
							/>
						))}
					{quantumType === 'devices' &&
						['Simulator', 'QPU', 'Qiskit', 'Braket'].map((label, index) => (
							<Tab
								// eslint-disable-next-line react/no-array-index-key
								key={`device tab ${index}`}
								onClick={() => {
									sendRGAEvent(`Devices ${label}`, 'Tab Click');
								}}
								label={label}
								value={label}
							/>
						))}
				</TabList>
				<Box>
					{!window.location.pathname.includes('hackathons') && (
						<Tooltip
							title={listFullscreen ? 'Collapse List' : 'Expand List'}
							arrow
							placement="left"
							TransitionProps={Zoom}
						>
							<IconButton onClick={handleFullscreenToggle}>
								{listFullscreen ? <FullscreenExit /> : <Fullscreen />}
							</IconButton>
						</Tooltip>
					)}
					{quantumType === 'devices' &&
						!window.location.pathname.includes('hackathons') && (
							<Tooltip
								title={deviceGridView ? 'List View' : 'Grid View'}
								arrow
								placement="left"
								TransitionProps={Zoom}
							>
								<IconButton onClick={handleGridViewChange}>
									{deviceGridView ? (
										<FormatListBulletedIcon sx={{ fontSize: '1em' }} />
									) : (
										<GridOnIcon sx={{ fontSize: '1em' }} />
									)}
								</IconButton>
							</Tooltip>
					)}
				</Box>
			</Box>
			{deviceGridView ? (
				<Grid
					container
					gap={2}
					sx={{
						padding: 2,
						maxHeight: listFullscreen ? '100vh' : 300,
						overflow: 'auto'
					}}
				>
					{(isJobsLoading && quantumType === 'jobs') ||
					(isDeviceLoading && quantumType === 'devices')
						? Array.from({ length: 6 }, (_, i) => i + 1).map((item) => (
								<Grid
									key={item}
									item
									container
									xs={12}
									padding={2}
									justifyContent="space-between"
									borderRadius="12px"
									boxShadow={2}
								>
									<Grid item xs={12} sm={12} md={8}>
										<Box display="flex" justifyContent="space-between">
											<Skeleton width={50} />
											<Skeleton width={100} />
										</Box>
										<Box
											width="100%"
											mt={1}
											boxShadow={1}
											padding={1}
											borderRadius="12px"
										>
											<Box display="flex" justifyContent="space-between">
												<Skeleton width={50} />
												<Skeleton width={100} />
											</Box>
											<Skeleton width={200} />
											<Skeleton width={200} />
											<Skeleton width={200} />
											<Skeleton width={200} />
											<Box display="flex" justifyContent="space-between">
												<Skeleton width={100} />
												<Skeleton width={100} />
											</Box>
											<Box display="flex" justifyContent="space-between">
												<Skeleton width={100} />
												<Skeleton width={100} />
											</Box>
											<Box display="flex" justifyContent="space-between">
												<Skeleton width={100} />
												<Skeleton width={100} />
											</Box>
										</Box>
									</Grid>
									<Grid
										item
										md={3}
										sx={{
											display: { xs: 'none', sm: 'none', md: 'flex' },
											justifyContent: 'center',
											alignItems: 'center',
											alignContent: 'center'
										}}
									>
										<Skeleton width="100%" height="100%" />
									</Grid>
								</Grid>
						  ))
						: getFromSelectedTab()}
				</Grid>
			) : (
				<TableContainer
					component={Paper}
					style={{
						maxHeight: listFullscreen ? '100vh' : 300,
						scrollbarGutter: 'stable'
					}}
				>
					<Table stickyHeader>
						<TableHead>
							{(isJobsLoading && quantumType === 'jobs') ||
							(isDeviceLoading && quantumType === 'devices') ? (
								<TableRow>
									<TableCell>
										<Skeleton />
									</TableCell>
									<TableCell>
										<Skeleton />
									</TableCell>
									<TableCell>
										<Skeleton />
									</TableCell>
									<TableCell>
										<Skeleton />
									</TableCell>
								</TableRow>
								) : quantumType === 'devices' ? (
								<TableRow>
									<TableCell>Provider</TableCell>
									<TableCell>Name</TableCell>
									<TableCell align="left">Status</TableCell>
									<TableCell />
								</TableRow>
								) : (
								<TableRow>
									<TableCell>Date</TableCell>
									<TableCell>Device ID</TableCell>
									<TableCell align="center">Provider</TableCell>
									<TableCell align="right">Status</TableCell>
									<TableCell />
								</TableRow>
								)}
						</TableHead>
						<TableBody>
							{(isJobsLoading && quantumType === 'jobs') ||
							(isDeviceLoading && quantumType === 'devices')
								? Array.from({ length: 6 }, (_, i) => i + 1).map((item) => (
										<TableRow key={item}>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
											<TableCell>
												<Skeleton />
											</TableCell>
										</TableRow>
								  ))
								: getFromSelectedTab()}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</TabContext>
	);

	const getFromSelectedTab = () => {
		/**
		 * Handles the selection tab to filter completed vs. in-progress jobs
		 */
		let filteredArray = [];
		const errorMessage = `Unable to fetch ${
			quantumType === 'jobs' ? 'job' : 'device'
		} information`;
		switch (true) {
			// quantum jobs
			case jobsSuccess && quantumType === 'jobs':
				switch (tableTab) {
					case 'Completed':
						filteredArray = quantumArray?.filter((job) => {
							if (job.qbraidStatus === 'COMPLETED') {
								return job;
							}
						});
						break;
					case 'In Progress':
						filteredArray = quantumArray?.filter((job) => {
							if (job.qbraidStatus !== 'COMPLETED') {
								return job;
							}
							return null;
						});
						break;
					default:
						filteredArray = quantumArray;
						break;
				}
				break;
			// quantum devices
			case deviceSuccess && quantumType === 'devices':
				switch (tableTab) {
					case 'Simulator':
						filteredArray = quantumArray?.filter((device) => {
							if (device.type === 'Simulator') {
								return device;
							}
							return null;
						});
						break;
					case 'QPU':
						filteredArray = quantumArray?.filter((device) => {
							if (device.type === 'QPU') {
								return device;
							}
							return null;
						});
						break;
					case 'Qiskit':
						filteredArray = quantumArray?.filter((device) => {
							if (device.runPackage === 'qiskit') {
								return device;
							}
							return null;
						});
						break;
					case 'Braket':
						filteredArray = quantumArray?.filter((device) => {
							if (device.runPackage === 'braket') {
								return device;
							}
							return null;
						});
						break;
					default:
						filteredArray = quantumArray;
						break;
				}
				break;
			default:
				// Failed to fetch data

				return deviceGridView ? (
					<Box
						textAlign="center"
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
						height="100px"
						boxShadow={1}
						borderRadius="12px"
					>
						<Typography>{errorMessage}</Typography>
					</Box>
				) : (
					<TableRow>{errorMessage}</TableRow>
				);
		}
		return filteredArray?.map((item) => (
			<Row
				snackbar={snackbar}
				row={item}
				key={item._id}
				quantumType={quantumType}
				gridView={deviceGridView}
				listFullscreen={listFullscreen}
			/>
		));
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>{getQuantumTable()}</Box>
	);
}
