import quantinuum from '../../../../../../../../assets/images/Quantinuum.png';
import AWSLogo from '../../../../../../../../assets/images/AWS_logo.png';
import Quera from '../../../../../../../../assets/images/QuEra.png';
import ionq from '../../../../../../../../assets/images/ionq.png';

export const challengesData = [
	{
		title: 'Amazon Braket Challenge',
		logoUrl: AWSLogo,
		gitLink:
			'https://account.qbraid.com/?gitHubUrl=https://github.com/iQuHACK/2024_AWS.git',
		link: 'https://github.com/iQuHACK/2024_AWS'
	},
	{
		title: 'QuEra Challenge',
		logoUrl: Quera,
		gitLink:
			'https://account.qbraid.com/?gitHubUrl=https://github.com/iQuHACK/2024_Quera.git',
		link: 'https://github.com/iQuHACK/2024_Quera'
	},
	{
		title: 'IonQ challenge',
		logoUrl: ionq,
		gitLink:
			'https://account.qbraid.com/?gitHubUrl=https://github.com/iQuHACK/2024_Ionq.git',
		link: 'https://github.com/iQuHACK/2024_Ionq'
	},
	{
		title: 'Quantinuum Challenge',
		logoUrl: quantinuum,
		gitLink:
			'https://account.qbraid.com/?gitHubUrl=https://github.com/iQuHACK/2024_Quantinuum.git',
		link: 'https://github.com/iQuHACK/2024_Quantinuum'
	},
];
