import { Circle, RadioButtonUnchecked } from '@mui/icons-material';
import {
	Box,
	Button,
	Chip,
	colors,
	Grid,
	Icon,
	IconButton,
	List,
	ListItem,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';
import { logosData } from '../deviceLogoData';

function DeviceCard({
	listFullscreen,
	row,
	providerLogo,
	quantumType,
	copyContent
}) {
	const muiTheme = useTheme();
	const getPricing = (device) => {
		if (!device?.pricing) {
			return 'Free';
		}

		const { perMinute, perShot, perTask } = device.pricing;

		if (perTask > 0 && perShot > 0) {
			return (
				<Box
					display="inline-flex"
					flexWrap="wrap"
					gap={1}
					alignItems="baseline"
				>
					<Typography fontSize={16} color="text.secondary">
						$
					</Typography>
					<Typography fontSize={26}>{perTask.toFixed(2)}</Typography>
					<Typography fontSize={16} color="text.secondary">
						/ per task{' '}
					</Typography>
					<Typography fontSize={26}>+</Typography>
					<Typography fontSize={16} color="text.secondary">
						${' '}
					</Typography>
					<Typography fontSize={26}>{perShot.toFixed(5)} </Typography>
					<Typography fontSize={16} color="text.secondary">
						/ per shot{' '}
					</Typography>
				</Box>
			);
		}

		if (perMinute > 0) {
			return (
				<Box
					display="inline-flex"
					flexWrap="wrap"
					gap={1}
					alignItems="baseline"
				>
					<Typography fontSize={16} color="text.secondary">
						$
					</Typography>
					<Typography fontSize={26}>{perMinute.toFixed(3)}</Typography>
					<Typography fontSize={16} color="text.secondary">
						/ per minute{' '}
					</Typography>
				</Box>
			);
		}

		return (
			<Typography fontSize={16} color="text.secondary">
				Free
			</Typography>
		);
	};
	const getDeviceImage = () => {
		let deviceUrl;
		// filter from providers
		const provider = logosData.filter(
			(data) =>
				data.provider.trim().toLowerCase() === row.provider.trim().toLowerCase()
		)[0];
		// filter from devices, if exist
		if (provider.devices) {
			deviceUrl = provider.devices.filter(device => device.name === row.name)[0]?.deviceImage
		}
		return deviceUrl;
	};

	const getCardHeader = () => {
		if (row.architecture) {
			if (row.provider.trim().toLowerCase() === 'quera') {
				return (
					<>
						<Typography
							fontFamily="Bai Jamjuree"
							fontWeight={300}
							sx={{ textTransform: 'capitalize' }}
							fontSize={22}
						>
							Neutral Atom
						</Typography>
						<Typography
							fontFamily="Bai Jamjuree"
							fontWeight={300}
							sx={{ textTransform: 'capitalize' }}
							fontSize={22}
						>
							Quantum Computer
						</Typography>
					</>
				);
			}
			if (row.provider.trim().toLowerCase() === 'ionq') {
				return (
					<>
						<Typography
							fontFamily="Bai Jamjuree"
							fontWeight={300}
							sx={{ textTransform: 'capitalize' }}
							fontSize={22}
						>
							Trapped Ion
						</Typography>
						<Typography
							fontFamily="Bai Jamjuree"
							fontWeight={300}
							sx={{ textTransform: 'capitalize' }}
							fontSize={22}
						>
							Quantum Computer
						</Typography>
					</>
				);
			}
			return (
				<>
					<Typography
						fontFamily="Bai Jamjuree"
						fontWeight={300}
						sx={{ textTransform: 'capitalize' }}
						fontSize={22}
					>
						Superconducting
					</Typography>
					<Typography
						fontFamily="Bai Jamjuree"
						fontWeight={300}
						sx={{ textTransform: 'capitalize' }}
						fontSize={22}
					>
						Quantum Computer
					</Typography>
				</>
			);
		}
		return (
			<Typography
				fontFamily="Bai Jamjuree"
				fontSize={22}
				fontWeight={300}
				sx={{ textTransform: 'capitalize' }}
			>
				Simulators and Emulators
			</Typography>
		);
	};
	const getProviderDescription = () =>
		logosData.filter(
			(data) =>
				data.provider.trim().toLowerCase() === row.provider.trim().toLowerCase()
		)[0]?.providerDescription;

	const getDeviceDescription = () => {
		const deviceArray = logosData.filter(
			(data) =>
				data.provider.trim().toLowerCase() === row.provider.trim().toLowerCase()
		)[0]?.devices;
		if (deviceArray?.length) {
			return deviceArray.filter((device) => device.name === row.name)[0]?.des;
		}
	};
	const getDeviceAboutLink = () => {
		const deviceArray = logosData.filter(
			(data) =>
				data.provider.trim().toLowerCase() === row.provider.trim().toLowerCase()
		)[0]?.devices;
		if (deviceArray?.length) {
			return deviceArray.filter((device) => device.name === row.name)[0]?.about;
		}
	};
	const getDeviceSpecArray = () => {
		const deviceArray = logosData.filter(
			(data) =>
				data.provider.trim().toLowerCase() === row.provider.trim().toLowerCase()
		)[0]?.devices;
		if (deviceArray?.length) {
			return deviceArray.filter((device) => device.name === row.name)[0]?.spec;
		}
	};
	const handleRedirection = (url) =>
		window.open(
			url,
			'_blank' // <- This is what makes it open in a new window.
		);

	return (
		<Grid
			item
			container
			xs={12}
			padding={{
				xs: 1,
				sm: 1,
				md: 2,
				lg: 3
			}}
			gap={{ xs: 1, sm: 1, md: 2 }}
			sx={{
				position: 'relative',
				backgroundColor:
					muiTheme.palette.mode === 'light'
						? colors.grey[100]
						: colors.grey[700],
				borderRadius: 3,
				backgroundImage: {
					xs: 'none',
					sm: 'none',
					md: `url('${getDeviceImage()}')`
				},
				backgroundRepeat: 'no-repeat',
				backgroundSize: {
					md: !listFullscreen
						? row.name?.toLowerCase() === 'Ankaa-2'.toLowerCase()
							? 'auto 10rem'
							: 'auto 15rem'
						: row.name?.toLowerCase() === 'Ankaa-2'.toLowerCase()
							? 'auto 18rem'
							: 'auto 22rem',
					lg: !listFullscreen
						? row.name?.toLowerCase() === 'Ankaa-2'.toLowerCase()
							? 'auto 15rem'
							: 'auto 22rem'
						: row.name?.toLowerCase() === 'Ankaa-2'.toLowerCase()
							? 'auto 25rem'
							: 'auto 35rem'
				},
				backgroundPosition: '100% 0'
			}}
		>
			<Grid
				item
				container
				xs={12}
				sm={12}
				md={getDeviceImage() ? 9 : 12}
				spacing={2}
			>
				<Grid
					item
					container
					spacing={1}
					justifyContent="space-between"
					alignItems="flex-start"
					flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
				>
					<Grid item order={{ xs: 2, sm: 2, md: 1 }}>
						{getCardHeader()}
					</Grid>
					<Grid item order={{ xs: 1, sm: 1, md: 2 }}>
						<img
							src={
								muiTheme.palette.mode === 'light'
									? providerLogo(row.provider).lightLogo
									: providerLogo(row.provider).darkLogo
							}
							alt="provider"
							width={100}
							style={{ objectFit: 'contain', aspectRatio: 2 / 5 }}
						/>
					</Grid>
				</Grid>
				{getProviderDescription() && (
					<Grid item xs={12}>
						<Typography fontSize={16} color="text.secondary">
							{getProviderDescription()}
						</Typography>
					</Grid>
				)}
				<Grid item container xs={12} gap={2} mt={1}>
					<Grid
						item
						container
						gap={2}
						justifyContent="space-between"
						flexWrap="wrap"
						sx={(theme) => ({
							backgroundColor: theme.palette.background.default,
							borderRadius: 2,
							padding: 2
						})}
					>
						<Grid
							item
							display="flex"
							gap={{ xs: '1em', sm: '1em', md: '2em' }}
							flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
							alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}
						>
							<Typography fontWeight={800} fontSize={24} fontStyle="italic">
								{row.name}
							</Typography>
							{getDeviceDescription() && (
								<Typography fontSize={16} color="text.secondary">
									{getDeviceDescription()}
								</Typography>
							)}
						</Grid>
						<Grid item>
							<Chip
								icon={
									row?.status?.toLowerCase() === 'online' ? (
										row.isAvailable === true ? (
											<Icon
												color="success"
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center'
												}}
											>
												<Circle sx={{ width: 15, height: 15 }} />
											</Icon>
										) : (
											<Icon
												color="action"
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center'
												}}
											>
												<RadioButtonUnchecked sx={{ width: 15, height: 15 }} />
											</Icon>
										)
									) : (
										<Icon
											color="error"
											sx={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											<Circle sx={{ width: 15, height: 15 }} />
										</Icon>
									)
								}
								label={
									row?.nextAvailable &&
										row?.nextAvailable !== null &&
										row.status === 'ONLINE'
										? 'Online'
										: row.status === 'RETIRED' || row.status === 'OFFLINE'
											? 'Unavailable'
											: row?.objArg?.includes('Forte-1')
												? 'Reservation only'
												: 'Reservation only'
								}
								color="default"
							/>
						</Grid>
						<Grid item xs={12}>
							{getPricing(row)}
						</Grid>
						<Grid item container gap={1} xs={12}>
							<Grid item xs={12}>
								<Typography fontSize={14} fontWeight={600}>
									Specifications:
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<List dense>
									{getDeviceSpecArray()?.map((des) => (
										<ListItem
											disableGutters
											sx={{ fontSize: 14, color: 'text.secondary' }}
										>
											{des}
										</ListItem>
									))}
									<ListItem
										disableGutters
										secondaryAction={
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: 600,
													color: 'text.primary'
												}}
											>
												{quantumType === 'devices'
													? row.paradigm
													: row.vendor === 'aws'
														? row?.vendorDeviceId?.split('/').pop()
														: row?.vendorDeviceId?.replaceAll('_', ' ')}
											</Typography>
										}
										sx={{ fontSize: 14, color: 'text.secondary' }}
									>
										{quantumType === 'devices' ? 'Paradigm' : 'Device Name'}
									</ListItem>
									<ListItem
										disableGutters
										secondaryAction={
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: 600,
													color: 'text.primary'
												}}
											>
												{quantumType === 'devices'
													? row.type
													: row.vendor === 'ibm'
														? 'IBM Qiskit'
														: row.vendor === 'aws'
															? 'Amazon Braket'
															: 'Not defined'}
											</Typography>
										}
										sx={{ fontSize: 14, color: 'text.secondary' }}
									>
										{quantumType === 'devices' ? 'Type' : 'Package'}
									</ListItem>
									<ListItem
										disableGutters
										secondaryAction={
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: 600,
													color: 'text.primary'
												}}
											>
												{row.runPackage}
											</Typography>
										}
										sx={{ fontSize: 14, color: 'text.secondary' }}
									>
										Access
									</ListItem>
									<ListItem
										disableGutters
										secondaryAction={
											<Typography
												sx={{
													fontSize: 14,
													fontWeight: 600,
													color: 'text.primary',
													whiteSpace: 'nowrap',
													textOverflow: 'ellipsis',
													overflow: 'hidden',
													maxWidth: { xs: 120, sm: 250, md: 'fit-content' }
												}}
											>
												{row?.objArg}
											</Typography>
										}
										sx={{ fontSize: 14, color: 'text.secondary' }}
									>
										<Box display="flex" gap={1}>
											{row.runPackage === 'braket' ? 'Device ARN' : 'Device ID'}
											<Tooltip arrow placement="left" title="Copy ID">
												<IconButton
													sx={{
														padding: '0px'
													}}
													size="small"
													onClick={() => {
														copyContent(
															`Copied ${row.runPackage === 'braket'
																? 'deviceArn'
																: 'deviceId'
															}: ${row?.objArg}`,
															row?.objArg
														);
													}}
												>
													<ContentCopyIcon
														sx={{
															fontSize: '20px !important'
														}}
													/>
												</IconButton>
											</Tooltip>
										</Box>
									</ListItem>
								</List>
							</Grid>
							{getDeviceAboutLink() && (
								<Grid item xs={12}>
									<Button
										variant="slide"
										onClick={() => {
											handleRedirection(getDeviceAboutLink());
										}}
										endIcon={<KeyboardArrowRightIcon />}
									>
										More About Device
									</Button>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default DeviceCard;
