import React from 'react';

function Discord({
	title, source, width, height 
}) {
	return (
		<iframe
			title={title}
			src={source}
			width={width}
			height={height}
			frameBorder="0"
			sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
		/>
	);
}

export default Discord;
