import { axiosClient } from '../../utils/axiosClient';
import {
	DEVICES_DATA_FAIL,
	DEVICES_DATA_LOADING,
	DEVICES_DATA_SUCCESS
} from '../Types/quantumDeviceTypes';
import { apiDown } from './loadingErrMsgAction';

export const QuantumDevicesFunction = () => async (dispatch) => {
	dispatch(DevicesLoading());

	try {
		const result = await axiosClient().get('/api/quantum-devices');
		let devices = result.data;
		devices = devices.filter((item) => item.provider !== 'Google');
		return dispatch(DevicesSuccess(devices));
	} catch (err) {
		if (err?.response?.status === 500) {
			dispatch(apiDown('API is down !'));
		}
		return dispatch(DevicesFail());
	}
};

export const DevicesLoading = () => ({
	type: DEVICES_DATA_LOADING
});

export const DevicesSuccess = (payload) => ({
	type: DEVICES_DATA_SUCCESS,
	payload
});

export const DevicesFail = () => ({
	type: DEVICES_DATA_FAIL
});
