import {
	ADD_TO_ACTIVE_USER_LOADING_ORGS,
	FETCH_ORG_USER_LIST_SUCCESS,
	FETCH_ORGLIST_FAILED,
	FETCH_ORGLIST_LOADING,
	FETCH_ORGLIST_SUCCESS,
	ORGLIST_LOADING_STOP,
	REMOVE_FROM_ACTIVE_USER_LOADING_ORGS,
	SET_SELECTED_ORG,
	SET_SELECTED_ORG_CURRENT_USER_ROLE
} from '../Types/organizationTypes';

// Subs are subscriptions
const initialState = {
	orgList: [],
	isLoading: false,
	success: false,
	activeOrgsLoadingUsers: [],
	selectedOrg:{},
	currentUserRole:''
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_ORGLIST_LOADING: {
			return {
				...state,
				isLoading: true
			};
		}
		case FETCH_ORGLIST_SUCCESS: {
			return {
				...state,
				isLoading: false,
				success: true,
				orgList: action.payload
			};
		}
		case FETCH_ORGLIST_FAILED: {
			return {
				...state,
				isLoading: false,
				success: false,
				orgList: []
			};
		}
		case ORGLIST_LOADING_STOP: {
			return {
				...state,
				isLoading: false
			};
		}
		case ADD_TO_ACTIVE_USER_LOADING_ORGS: {
			return {
				...state,
				activeOrgsLoadingUsers: [
					...new Set([...state.activeOrgsLoadingUsers, action.payload])
				]
			};
		}
		case REMOVE_FROM_ACTIVE_USER_LOADING_ORGS: {
			const filteredOgIds=state.activeOrgsLoadingUsers.filter(
				(orgId) => orgId !== action.payload
			)
			return {
				...state,
				activeOrgsLoadingUsers: filteredOgIds
			};
		}
		case FETCH_ORG_USER_LIST_SUCCESS: {
			// const updatedOrgList=state.orgList.organizations.map(org=>{
			// 	if(org.org.organization._id===action.payload.orgId){
			// 		return {...org,users:action.payload.orgUsers}
			// 	}
			// 	return org
			// })
			return {
				...state,
				// orgList:{organizations: updatedOrgList} // this operation is good for caching users, but it will initialize a new rendering due to useEffect.
			};
		}
		case SET_SELECTED_ORG:{
			return {
				...state,
				selectedOrg: action.payload
			};
		}
		case SET_SELECTED_ORG_CURRENT_USER_ROLE:{
			return {
				...state,
				currentUserRole: action.payload
			};
		}
		
		default:
			return {
				...state
			};
	}
}
