/* eslint-disable no-lonely-if */
import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Collapse,
	Icon,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material';
import { format } from 'date-fns';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from 'moment';
import {
	CancelOutlined,
	PendingOutlined,
	CheckCircleOutline
} from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { fallBackLogo, logosData } from './deviceLogoData';
import DeviceCard from './DeviceCard';

export default function Row(props) {
	const muiTheme = useTheme();
	const {
		row, snackbar, quantumType, gridView, listFullscreen 
	} = props;
	const [openCollapseTable, setOpenCollapseTable] = useState(false);
	const [time, setTime] = useState({
		hours: '00',
		minutes: '00',
		seconds: '00'
	});
	const timer = useRef(null);
	const handlePopoverOpen = () => {
		// setAnchorEl(event.currentTarget);
		setOpenCollapseTable((prev) => !prev);
	};

	const copyContent = (message, content) => {
		navigator.clipboard.writeText(content).then(() =>
			snackbar({
				msg: message,
				open: true,
				color: false
			}));
	};

	const formatDate = (dateString) => {
		/**
		 * Custom formatting for the date string in first column of table
		 */
		let date = new Date();
		if (dateString) {
			date = new Date(dateString);
		}
		const year = date.getFullYear();
		const month = format(date, 'MMMM');
		const day = date.getDate();
		// eslint-disable-next-line no-shadow
		const time = format(date, 'HH:mm');
		return `${month} ${day}, ${year}  ${time}`;
	};

	const statusIcon = () => {
		/**
		 * Render the corresponding status icon depending on the job state. The list of possible states
		 * are the following:
		 *    INITIALIZING, QUEUED, RUNNING, CANCELLING, CANCELLED, COMPLETED, FAILED
		 */
		const returnedStatus = ['COMPLETED'];
		const errorStatus = ['FAILED', 'CANCELLED'];
		let statusElement = null;
		if (quantumType === 'devices') {
			statusElement = (
				<Tooltip
					arrow
					placement="top-end"
					title={
						row.status?.toLowerCase() === 'online'
							? row.isAvailable === true
								? 'Available'
								: 'Unavailable'
							: 'Offline'
					}
					PopperProps={{
						sx: (theme) => ({
							'& .MuiTooltip-tooltip': {
								backgroundColor: theme.palette.background.paper,
								color: theme.palette.text.primary,
								boxShadow: theme.shadows[5],
								fontSize: '14px'
							},
							'& .MuiTooltip-arrow': {
								color: theme.palette.background.paper
							}
						})
					}}
				>
					{row?.status?.toLowerCase() === 'online' ? (
						row.isAvailable === true ? (
							<Icon
								color="success"
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<CircleIcon sx={{ width: 15, height: 15 }} />
							</Icon>
						) : (
							<Icon
								color="action"
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<RadioButtonUncheckedIcon sx={{ width: 15, height: 15 }} />
							</Icon>
						)
					) : (
						<Icon
							color="error"
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<CircleIcon sx={{ width: 15, height: 15 }} />
						</Icon>
					)}
				</Tooltip>
			);
		} else {
			if (returnedStatus.includes(row?.qbraidStatus)) {
				statusElement = (
					<Icon color="success">
						<CheckCircleOutline sx={{ width: 24, height: 24 }} />
					</Icon>
				);
			} else if (errorStatus.includes(row?.qbraidStatus)) {
				statusElement = (
					<Icon color="error">
						<CancelOutlined sx={{ width: 24, height: 24 }} />
					</Icon>
				);
			} else {
				statusElement = (
					<Icon color="action">
						<PendingOutlined sx={{ width: 24, height: 24 }} />
					</Icon>
				);
			}
		}
		return statusElement;
	};
	// fetches the logo image from logosData that corresponds with current data value i.e. props.row.provider
	// if logo is not available for the current device, then get a default logo i.e. fallBackLogo
	const providerLogo = (provider) => {
		let darkLogo;
		let lightLogo;
		if (quantumType === 'devices') {
			lightLogo =
				logosData.filter(
					(data) =>
						data.provider.trim().toLowerCase() === provider.trim().toLowerCase()
				)[0]?.url || fallBackLogo;
			darkLogo =
				logosData.filter(
					(data) =>
						data.provider.trim().toLowerCase() === provider.trim().toLowerCase()
				)[0]?.urlDark || fallBackLogo;
			return { lightLogo, darkLogo };
		}
		lightLogo = row.logo;
		darkLogo = row.logoDark;
		return { lightLogo, darkLogo };
	};
	function msToTime(duration) {
		// let milliseconds = parseInt(duration % 1000, 10);
		// eslint-disable-next-line no-shadow
		let seconds = Math.floor((duration / 1000) % 60);
		// eslint-disable-next-line no-shadow
		let minutes = Math.floor((duration / (1000 * 60)) % 60);
		// eslint-disable-next-line no-shadow
		let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

		hours = hours.toString().padStart(2, '0');
		minutes = minutes.toString().padStart(2, '0');
		seconds = seconds.toString().padStart(2, '0');
		// milliseconds = milliseconds.toString().padStart(3, '0');

		return {
			hours: hours,
			minutes: minutes,
			seconds: seconds
		};
	}

	function runTimer() {
		if (!row.nextAvailable) {
			return;
		}
		const diff = Date.now();

		// If you want to count up
		// this.setState(() => ({
		//  time: this.msToTime(diff)
		// }));

		// count down
		let remaining = new Date(row.nextAvailable) - diff;
		if (remaining < 0) {
			remaining = 0;
		}
		setTime(msToTime(remaining));
		if (remaining === 0) {
			window.clearTimeout(timer.current);
			timer.current = null;
		}
	}
	// eslint-disable-next-line no-shadow
	// const getPricing = (row) => {
	// 	if (!row?.pricing) {
	// 		return 'Free';
	// 	}

	// 	const { perMinute, perShot, perTask } = row.pricing;

	// 	if (perTask > 0 && perShot > 0) {
	// 		return (
	// 			<p style={{ margin: 'unset' }}>
	// 				$ <b>{perTask.toFixed(2)}</b>
	// 				{`/ per task `}
	// 				<b>+</b> $ <b>{perShot.toFixed(2)}</b>
	// 				{` / per shot`}
	// 			</p>
	// 		);
	// 	}

	// 	if (perMinute > 0) {
	// 		return (
	// 			<p style={{ margin: 'unset' }}>
	// 				$ <b>{perMinute.toFixed(2)}</b>/ per minute
	// 			</p>
	// 		);
	// 	}

	// 	return 'Free';
	// };
	useEffect(() => {
		if (row.nextAvailable && row.nextAvailable !== null) {
			timer.current = window.setInterval(() => runTimer(), 10);
		}
	}, [row]);

	const RowComponent = (
		<>
			<TableRow>
				{quantumType === 'jobs' && (
					<TableCell>{formatDate(row?.timeStamps?.createdAt || row?.createdAt)}</TableCell>
				)}
				{quantumType === 'jobs' && (
					<TableCell component="th" scope="row">
						{row?.qbraidDeviceId}
					</TableCell>
				)}
				<TableCell align={quantumType === 'jobs' ? 'center' : 'left'}>
					<img
						src={
							muiTheme.palette.mode === 'light'
								? providerLogo(row?.provider).lightLogo
								: providerLogo(row?.provider).darkLogo
						}
						alt="provider"
						height={18}
						width={45}
						style={{ objectFit: 'contain' }}
					/>
				</TableCell>
				{quantumType === 'devices' && (
					<TableCell align={quantumType === 'jobs' ? 'center' : 'left'}>
						{row.name}
					</TableCell>
				)}
				<TableCell align="right">{statusIcon()}</TableCell>
				<TableCell>
					<IconButton
						aria-describedby="id"
						size="small"
						onClick={handlePopoverOpen}
						aria-label="more-options"
						title="More options"
					>
						{openCollapseTable ? (
							<KeyboardArrowDownIcon />
						) : (
							<KeyboardArrowRightIcon />
						)}
					</IconButton>
					{/* <Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
			>
				{getPopoverContents()}
			</Popover> */}
				</TableCell>
			</TableRow>
			<TableRow
				sx={(theme) => ({
					backgroundImage:
						theme.palette.mode === 'light'
							? 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)'
							: 'linear-gradient(-20deg,var(--bg-clr1) 0%,var(--bg-clr2) 100%)'
				})}
			>
				<TableCell
					sx={{
						padding: 0 // background and border
					}}
					colSpan={6}
				>
					<Collapse in={openCollapseTable} timeout="auto" unmountOnExit>
						<Box>
							<Table aria-label="purchases" size="small">
								<TableBody>
									<TableRow>
										<TableCell
											component="th"
											scope="row"
											sx={{ fontWeight: 600 }}
										>
											{quantumType === 'devices' ? 'Paradigm' : 'Device Name'}
										</TableCell>
										<TableCell variant="body" sx={{ fontSize: '14px' }}>
											{quantumType === 'devices'
												? row.paradigm
												: row.vendor === 'aws'
													? row?.vendorDeviceId?.split('/').pop()
													: row?.vendorDeviceId?.replaceAll('_', ' ')}
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell
											component="th"
											scope="row"
											sx={{ fontWeight: 600 }}
										>
											{quantumType === 'devices' ? 'Type' : 'Run Package'}
										</TableCell>
										<TableCell variant="body" sx={{ fontSize: '14px' }}>
											{quantumType === 'devices'
												? row.type
												: row.vendor === 'ibm'
													? 'IBM Qiskit'
													: row.vendor === 'aws'
														? 'Amazon Braket'
														: 'Not defined'}
										</TableCell>
									</TableRow>
									{quantumType === 'devices' && (
										<>
											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													{row.status === 'ONLINE' && row.isAvailable === true
														? 'Pending Jobs'
														: 'Next Available'}
												</TableCell>
												<TableCell
													variant="body"
													sx={{
														fontSize: '14px',
														display: 'flex',
														alignItems: 'center',
														gap: '8px'
													}}
													data-testid="qDevice-status-text"
												>
													{row?.nextAvailable && row?.isAvailable === false && (
														<AccessTimeIcon fontSize="14px" />
													)}
													{row?.nextAvailable &&
													row?.nextAvailable !== null &&
													row.status === 'ONLINE'
														? `${time.hours}:${time.minutes}:${time.seconds}`
														: row.status === 'RETIRED' ||
														  row.status === 'OFFLINE'
															? 'Unavailable'
															: row?.objArg?.includes('Forte-1')
																? 'Reservation only'
																: row.pendingJobs}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													Access
												</TableCell>
												<TableCell variant="body" sx={{ fontSize: '14px' }}>
													{row.runPackage}
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													{row.runPackage === 'braket'
														? 'Device ARN'
														: 'Device ID'}
												</TableCell>
												<TableCell sx={{ paddingRight: '4px' }}>
													<Stack
														direction="row"
														alignItems="center"
														gap={0.5}
														position="relative"
													>
														<Tooltip arrow placement="left" title="Copy ID">
															<IconButton
																sx={{
																	padding: '0px'
																}}
																size="small"
																onClick={() => {
																	copyContent(
																		`Copied ${
																			row.runPackage === 'braket'
																				? 'Device ARN'
																				: 'Device ID'
																		}: ${row?.objArg}`,
																		row?.objArg
																	);
																}}
															>
																<ContentCopyIcon
																	sx={{
																		fontSize: '20px !important'
																	}}
																/>
															</IconButton>
														</Tooltip>
														<Typography
															fontSize={14}
															fontWeight={600}
															variant="body1"
															overflow="hidden"
															textOverflow="ellipsis"
															display="-webkit-box"
															sx={{
																WebkitLineClamp: 3,
																WebkitBoxOrient: 'vertical'
															}}
														>
															{row?.objArg}
														</Typography>
													</Stack>
												</TableCell>
											</TableRow>
										</>
									)}

									{quantumType === 'jobs' && (
										<>
											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													Creation Time
												</TableCell>
												<TableCell variant="body" sx={{ fontSize: '14px' }}>
													{moment(row?.timeStamps?.jobStarted).format(
														'MMM DD, YYYY hh:mm A'
													)}
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													Status
												</TableCell>
												<TableCell variant="body" sx={{ fontSize: '14px' }}>
													{row.qbraidStatus !== undefined
														? row.qbraidStatus
														: row.status
															? row.status
															: 'Not defined'}
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													{row?.vendorJobId?.startsWith('arn', 0)
														? 'Job ARN'
														: 'Job ID'}
												</TableCell>
												<TableCell
													variant="body"
													sx={{
														fontSize: '14px',
														display: 'flex',
														alignItems: 'center',
														gap: '8px'
													}}
												>
													<Stack
														direction="row"
														alignItems="center"
														gap={0.5}
														position="relative"
													>
														<Tooltip arrow placement="left" title="Copy ID">
															<IconButton
																sx={{
																	padding: '0px'
																}}
																size="small"
																onClick={() => {
																	copyContent(
																		row?.vendor === 'aws'
																			? `Copied AWS jobs ARN: ${row?.vendorJobId}`
																			: `Copied IBM jobs ID: ${row?.vendorJobId}`,
																		row?.vendorJobId
																	);
																}}
															>
																<ContentCopyIcon
																	sx={{
																		fontSize: '20px !important'
																	}}
																/>
															</IconButton>
														</Tooltip>
														<Typography
															fontSize={14}
															fontWeight={600}
															variant="body1"
															overflow="hidden"
															textOverflow="ellipsis"
															display="-webkit-box"
															sx={{
																WebkitLineClamp: 3,
																WebkitBoxOrient: 'vertical'
															}}
														>
															{row?.vendorJobId}
														</Typography>
													</Stack>
												</TableCell>
											</TableRow>

											{row?.qbraidJobId !== undefined && (
												<TableRow>
													<TableCell
														component="th"
														scope="row"
														sx={{ fontWeight: 600 }}
													>
														{row?.qbraidJobId?.startsWith('arn', 0)
															? 'qBraid Job ARN'
															: 'qBraid Job ID'}
													</TableCell>
													<TableCell variant="body" sx={{ fontSize: '14px' }}>
														<Stack
															direction="row"
															alignItems="center"
															gap={0.5}
															position="relative"
														>
															<Tooltip arrow placement="left" title="Copy ID">
																<IconButton
																	sx={{
																		padding: '0px'
																	}}
																	size="small"
																	onClick={() => {
																		copyContent(
																			`Copied qBraid Job ID: ${row?.qbraidJobId}`,
																			row?.qbraidJobId
																		);
																	}}
																>
																	<ContentCopyIcon
																		sx={{
																			fontSize: '20px !important'
																		}}
																	/>
																</IconButton>
															</Tooltip>
															<Typography
																fontSize={14}
																fontWeight={600}
																variant="body1"
																overflow="hidden"
																textOverflow="ellipsis"
																display="-webkit-box"
																sx={{
																	WebkitLineClamp: 3,
																	WebkitBoxOrient: 'vertical'
																}}
															>
																{row?.qbraidJobId}
															</Typography>
														</Stack>
													</TableCell>
												</TableRow>
											)}

											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													{row?.vendorDeviceId?.startsWith('arn', 0)
														? 'Device ARN'
														: 'Device ID'}
												</TableCell>
												<TableCell sx={{ paddingRight: '4px' }}>
													<Stack
														direction="row"
														alignItems="center"
														gap={0.5}
														position="relative"
													>
														<Tooltip arrow placement="left" title="Copy ID">
															<IconButton
																sx={{
																	padding: '0px'
																}}
																size="small"
																onClick={() => {
																	copyContent(
																		`Copied vendor device id: ${row?.vendorDeviceId}`,
																		row?.vendorDeviceId
																	);
																}}
															>
																<ContentCopyIcon
																	sx={{
																		fontSize: '20px !important'
																	}}
																/>
															</IconButton>
														</Tooltip>
														<Typography
															fontSize={14}
															fontWeight={600}
															variant="body1"
															overflow="hidden"
															textOverflow="ellipsis"
															display="-webkit-box"
															sx={{
																WebkitLineClamp: 3,
																WebkitBoxOrient: 'vertical'
															}}
														>
															{row?.vendorDeviceId}
														</Typography>
													</Stack>
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													Shots
												</TableCell>
												<TableCell sx={{ paddingRight: '4px' }}>
													{row.shots}
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													costEstimate
												</TableCell>
												<TableCell sx={{ paddingRight: '4px' }}>
													{row.costEstimate !== 'N/A'
														? row.costEstimate === 0
															? '> 0.01 credits'
															: `${parseFloat(row.costEstimate).toFixed(
																2
															  )} credits`
														: `${row.costEstimate}`}
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													circuitNumQubits
												</TableCell>
												<TableCell sx={{ paddingRight: '4px' }}>
													{row?.circuitBatchNumQubits?.length > 0
														? row?.circuitBatchNumQubits[0]
														: row.circuitNumQubits !== undefined
															? row.circuitNumQubits
															: 'Not defined'}
												</TableCell>
											</TableRow>

											<TableRow>
												<TableCell
													component="th"
													scope="row"
													sx={{ fontWeight: 600 }}
												>
													Circuit Depth
												</TableCell>
												<TableCell sx={{ paddingRight: '4px' }}>
													{row?.circuitBatchDepth?.length > 0
														? row.circuitBatchDepth[0]
														: 'Not defined'}
												</TableCell>
											</TableRow>
										</>
									)}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
	return quantumType === 'devices' && gridView ? (
		<DeviceCard
			row={row}
			listFullscreen={listFullscreen}
			providerLogo={providerLogo}
			quantumType="devices"
			copyContent={copyContent}
		/>
	) : (
		RowComponent
	);
}

// Row.propTypes = {
// 	row: PropTypes.shape({
// 		status: PropTypes.string.isRequired,
// 		deviceId: PropTypes.string,
// 		circuitDepth: PropTypes.number,
// 		circuitNumQubits: PropTypes.number
// 	}).isRequired
// };
