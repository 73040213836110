import {
	Box,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

const fixDate = (updatedAt) => {
	const date = new Date(updatedAt);
	const month = format(date, 'MMMM');
	const day = date.getDate();
	const time = format(date, 'HH:mm:ss');
	return `${month} ${day},  ${time}`;
};

function TeamTable({
	textColorPrimary,
	isLoading,
	tableRows,
	handleTableRowClick,
	selectedRow
}) {
	return (
		<TableContainer
			sx={{
				width: '100%',
				overflowX: 'auto',
				overflowY: 'scroll',
				height: '250px'
			}}
		>
			<Table aria-label="scquantum team table">
				<TableHead>
					<TableRow>
						<TableCell
							sx={{
								borderBottom: `3px solid ${textColorPrimary}`,
								color: textColorPrimary,
								fontWeight: 600,
								fontSize: '12px'
							}}
						>
							Team name
						</TableCell>
						<TableCell
							sx={{
								borderBottom: `3px solid ${textColorPrimary}`,
								color: textColorPrimary,
								fontWeight: 600,
								fontSize: '12px'
							}}
						>
							Total team members
						</TableCell>
						<TableCell
							sx={{
								borderBottom: `3px solid ${textColorPrimary}`,
								color: textColorPrimary,
								fontWeight: 600,
								fontSize: '12px'
							}}
						>
							Last 10 Scores
						</TableCell>
						<TableCell
							sx={{
								borderBottom: `3px solid ${textColorPrimary}`,
								color: textColorPrimary,
								fontWeight: 600,
								fontSize: '12px'
							}}
						>
							Top Score
						</TableCell>
						<TableCell
							sx={{
								borderBottom: `3px solid ${textColorPrimary}`,
								color: textColorPrimary,
								fontWeight: 600,
								fontSize: '12px'
							}}
						>
							Total submissions
						</TableCell>
						<TableCell
							sx={{
								borderBottom: `3px solid ${textColorPrimary}`,
								color: textColorPrimary,
								fontWeight: 600,
								fontSize: '12px'
							}}
						>
							Last updated
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody
					sx={{
						overflow: 'scroll',
						maxHeight: '10px'
					}}
				>
					{isLoading ? (
						<>
							<TableRow>
								<TableCell colSpan={6}>
									<Skeleton variant="rectangle" height={28} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={6}>
									<Skeleton variant="rectangle" height={28} />
								</TableCell>
							</TableRow>
						</>
					) : (
						tableRows?.map((row, _index) => (
							<TableRow
								key={row?.id}
								hover
								onClick={() => handleTableRowClick(row)}
								selected={selectedRow.id === row.id}
								sx={{
									userSelect: 'none',
									cursor: 'pointer',
									'&:last-child td, &:last-child th': { border: 0 },
									'&.Mui-selected': {
										background:
											'linear-gradient(-153.68deg, #bf3c6250 0%, #e2e419a6 291.57%)'
									}
								}}
							>
								<TableCell
									sx={{
										color: textColorPrimary,
										fontWeight: 600,
										fontSize: '12px'
									}}
									component="th"
									scope="row"
								>
									{row?.teamName}
								</TableCell>
								<TableCell
									sx={{
										color: textColorPrimary,
										fontWeight: 600,
										fontSize: '12px'
									}}
								>
									{row?.teamMembers.length}
								</TableCell>
								<TableCell
									sx={{
										color: textColorPrimary,
										fontWeight: 600,
										fontSize: '12px'
									}}
								>
									<Box display="flex" gap={1}>
										{row.submissions.slice(0, 9).map((submission) => (
											<Typography
												key={submission._id}
												fontWeight="inherit"
												fontSize="inherit"
												sx={{
													backgroundColor:
														submission._id === row.topSubmission
															? '#74b0b3'
															: '',
													color:
														submission._id === row.topSubmission
															? 'white'
															: 'inherit',
													padding: 0.5,
													borderRadius: 1
												}}
											>
												{submission.score}
											</Typography>
										))}
									</Box>
								</TableCell>
								<TableCell
									sx={{
										color: textColorPrimary,
										fontWeight: 600,
										fontSize: '12px'
									}}
								>
									{row?.topScore}
								</TableCell>
								<TableCell
									sx={{
										color: textColorPrimary,
										fontWeight: 600,
										fontSize: '12px'
									}}
								>
									{row?.totalSubmissions}
								</TableCell>

								<TableCell
									sx={{
										color: textColorPrimary,
										fontWeight: 600,
										fontSize: '12px'
									}}
								>
									{row?.updatedAt && fixDate(row?.updatedAt)}
								</TableCell>
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default TeamTable;
