import React from 'react';
import { Grid, Typography } from '@mui/material';

function AboutHackathon({ image, color, description }) {
	return (
		<Grid
			sx={{
				padding: '5rem 1rem',
				display: 'flex',
				justifyContent: { xs: 'flex-start', sm: 'center' },
				alignItems: 'center'
			}}
			container
			spacing={2}
		>
			<Grid
				item
				sm={12}
				xs={12}
				md={6}
				lg={6}
				xl={6}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: { xs: 'flex-start', sm: 'center' }
				}}
			>
				<img
					src={image.source}
					alt=""
					loading="lazy"
					height={image.size.height}
					width={image.size.width}
				/>
			</Grid>

			<Grid
				item
				sm={12}
				xs={12}
				md={6}
				lg={6}
				xl={6}
				sx={{
					display: 'flex',
					justifyContent: { xs: 'flex-start', sm: 'center' },
					alignItems: 'center'
				}}
			>
				<Typography
					sx={(theme) => ({
						width: '350px',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '25px',
						lineHeight: '38px',
						textAlign: { xs: 'left', sm: 'center' },
						backgroundImage:
							theme.palette.mode === 'light'
								? `radial-gradient(149.01% 149.01% at 36.3% 0%, ${color.primary} 0%, ${color.secondary} 100%)`
								: `radial-gradient(149.01% 149.01% at 36.3% 0%, ${theme.palette.text.primary} 0%, ${theme.palette.text.disabled} 100%)`,
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent',
						backgroundClip: 'text',
						textFillColor: 'transparent'
					})}
				>
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
}

AboutHackathon.defaultProps = {
	image: {
		source: '/static/images/hackathon.png',
		size: {
			height: 300,
			width: 300
		}
	},
	color: {
		primary: '#B613D0',
		secondary: '#1F0723'
	},
	description: 'qBraid is looking to empower the quantum ecosystem with HAQS'
};

export default AboutHackathon;
