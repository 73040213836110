import React, { useState } from 'react';
import {
	Button,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function Header({ navItems, headerLogo, colors }) {
	const [anchorElNav, setAnchorElNav] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<Grid
			item
			xs={12}
			sx={(theme) => ({
				padding: '10px 16px !important',
				backgroundColor: theme.palette.background.default,
				zIndex: 99,
				position: 'sticky',
				top: 0
			})}
		>
			<Grid container spacing={2}>
				<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
					<img
						alt="logo"
						src={headerLogo}
						style={{
							width: '250px',
							// height: '100px',
							objectFit: 'fill !important',
							borderRadius: '15px'
						}}
					/>
				</Grid>

				<Grid
					item
					xs={2}
					sm={2}
					md={4}
					lg={4}
					xl={4}
					sx={{
						display: {
							xs: 'none',
							sm: 'none',
							md: 'flex',
							lg: 'flex',
							xl: 'flex'
						},
						justifyContent: 'center',
						alignItems: 'end',
						marginBottom: '5px'
					}}
				>
					<Toolbar
						sx={{
							width: '100%',
							minHeight: '30px',
							marginLeft: '300px !important',
							display: 'flex',
							justifyContent: 'space-between',
							'&:after': {
								content: '""',
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								background: `linear-gradient(153.68deg, ${colors.primary} 0%, ${colors.secondary} 291.57%)`,
								transform: 'skew(-10deg)',
								borderRadius: '10px 2px 10px 2px',
								zIndex: -1
							}
						}}
						variant="dense"
					>
						{navItems.map((item) => (
							<Button
								onClick={item.clickEvent}
								key={item.title}
								sx={{
									fontSize: {
										md: '10px',
										lg: '12px',
										xl: '15px'
									},
									padding: '0 1rem',
									color: '#fff',
									'&:after': {
										content: '""',
										pointerEvents: 'none',
										bottom: '-1px',
										left: '0%',
										position: 'absolute',
										width: '0%',
										height: '2px',
										backgroundColor: 'currentColor',
										transitionTimingFunction:
											'cubic-bezier(0.25, 0.8, 0.25, 1)',
										transitionDuration: '400ms',
										transitionProperty: 'width, left'
									},
									'&:hover::after': {
										left: 0,
										width: '100%'
									}
								}}
							>
								{item.title}
							</Button>
						))}
					</Toolbar>
				</Grid>

				<Grid
					item
					xs={9}
					sx={{
						justifyContent: 'flex-end',
						alignItems: 'center',
						display: {
							xs: 'flex',
							sm: 'flex',
							md: 'none',
							lg: 'none',
							xl: 'none'
						}
					}}
				>
					<IconButton onClick={handleOpenNavMenu} color="inherit">
						<MenuIcon />
					</IconButton>

					<Menu
						id="menu-appbar"
						anchorEl={anchorElNav}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
						keepMounted
						open={Boolean(anchorElNav)}
						onClose={handleCloseNavMenu}
					>
						{navItems.map((page) => (
							<MenuItem key={page.title} onClick={page.clickEvent}>
								<Typography
									// onClick={(e) => { handleCloseNavMenu(e) }}
									textAlign="center"
								>
									{page.title}
								</Typography>
							</MenuItem>
						))}
					</Menu>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Header;
