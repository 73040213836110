import React from 'react';
import {
	Card, CardContent, Grid, Stack, Typography 
} from '@mui/material';
import QbraidWhiteLogo from '../../../../../../assets/images/qbraid_white_logo.png';
import github from '../../../../../../assets/images/github_new.png';

function ChallangeCard({ data, colors, logoSize }) {
	return (
		<Card
			sx={{
				flex: 1,
				background: `linear-gradient(153.68deg, ${colors.primary} 0%, ${colors.secondary} 291.57%)`
			}}
		>
			<CardContent
				sx={{
					'&:last-child': {
						paddingBottom: '16px'
					}
				}}
			>
				<Grid container spacing={0} justifyContent="space-between">
					<Grid item xs={12}>
						<img
							src={data.logoUrl}
							alt=""
							height={logoSize.height}
							loading="lazy"
						/>
					</Grid>

					<Grid item sm={6} xs={12}>
						<Typography
							variant="h5"
							sx={{
								color: '#FFFFFF',
								fontSize: '20px',
								fontWeight: 600
							}}
						>
							{data.title}
						</Typography>
					</Grid>

					<Grid
						item
						sm={5}
						xs={12}
						sx={{
							marginTop: { xs: '1rem', sm: '0rem' }
						}}
					>
						<Grid container>
							<Grid item xs={2}>
								<a href={data?.link} target="_blank" rel="noopener noreferrer">
									<img
										src={github}
										alt=""
										style={{
											width: '30px'
										}}
									/>
								</a>
							</Grid>
							<Grid item xs={10}>
								<Stack
									sx={{
										WebkitUserSelect: 'none',
										MsUserSelect: 'none',
										userSelect: 'none',
										cursor: 'pointer',
										borderRadius: '4px',
										display: 'flex',
										justifyContent: 'center',
										backgroundColor: '#000000'
									}}
									direction="row"
									alignItems="center"
									gap={1}
								>
									<a
										href={data?.gitLink}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Typography
											variant="h5"
											sx={{
												color: '#FFFFFF',
												fontSize: '15px',
												fontWeight: 600,
												float: 'left',
												lineHeight: '2rem'
											}}
										>
											Launch on
										</Typography>

										<img
											src={QbraidWhiteLogo}
											alt=""
											style={{
												float: 'right',
												width: '85px'
											}}
										/>
									</a>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

export default ChallangeCard;
