import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Button,
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
	useTheme
} from '@mui/material';
import Swal from 'sweetalert2';
import { axiosClient, email, LAB_URL } from '../../../../utils/axiosClient';
import { sendRGAEvent } from '../../../../utils/googleAnalytics';
import { SetSelectedComputeProfileObj } from '../../../../Redux/Action/computeAction';
import quantum1 from '../../../../assets/images/quantum1.webp';
import quantum8 from '../../../../assets/images/quantum8.png';

function ComputeInstance() {
	const muiTheme = useTheme();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { userDetails } = useSelector((state) => state.userReducer);
	const { computeProfile, selectedProfileObj } = useSelector(
		(state) => state.computeReducer
	);
	const billingSelector = useSelector((state) => state.billingReducer);
	const dispatch = useDispatch();

	const handleSelectComputeInstance = (computeObj) => {
		dispatch(SetSelectedComputeProfileObj(computeObj));
	};

	const handleLaunchLab = async () => {
		sendRGAEvent('Launch Lab', 'Button Click');
		setTimeout(() => {
			if (userDetails?.metadata?.newUser) {
				window.open(`${LAB_URL}/hub/home`, '_blank').focus();
			} else {
				window.open(`${LAB_URL}`, '_blank').focus();
			}
		}, 20);
		if (email) {
			setIsSubmitting(true);
			try {
				const response = await axiosClient().post('/api/lab/compute/tokens', {
					labUrl: LAB_URL
				});
				if (response?.status === 200) {
					if (
						selectedProfileObj?.slug.includes('GPU') &&
						billingSelector.qbraidCredits < 200
					) {
						setIsSubmitting(false);
						Swal.fire({
							title: 'Insufficient Credits',
							text: 'You do not have enough credits to start a GPU server.',
							icon: 'warning',
							confirmButtonColor: muiTheme.palette.primary.main
						});
						return;
					}
					axiosClient()
						.post('/api/lab/compute/start/server', {
							labToken: response?.data?.token?.token,
							labURL: LAB_URL,
							slug: selectedProfileObj.slug || '2vCPU_4GB'
						})
						.then((resp) => {
							if (resp?.status === 201 || resp?.status === 202) {
								setIsSubmitting(false);
								Swal.fire({
									icon: 'success',
									title: 'Server Started',
									text: `Redirecting to Lab ${LAB_URL}`,
									allowOutsideClick: false,
									allowEscapeKey: false,
									reverseButtons: true,
									timer: 2000
								});
							}
						})
						.catch((err) => {
							if (err?.response?.status === 400) {
								setIsSubmitting(false);

								Swal.fire({
									title: 'Redirecting',
									text: 'please wait....',
									icon: 'success',
									timer: 2000
								});
							} else if (err?.response?.status === 403) {
								setIsSubmitting(false);
								Swal.fire({
									title: 'Token not Authorized please try again',
									timer: 2000
								});
							} else if (err?.response?.status === 402) {
								setIsSubmitting(false);
								Swal.fire({
									title: 'Insufficient Credits',
									text: 'You do not have enough credits to start a GPU server.',
									icon: 'warning',
									confirmButtonColor: muiTheme.palette.primary.main
								});
							} else {
								setIsSubmitting(false);
								// the new user field is by default true when a user signup,
								// so that field is used to distinguish new and old user
							}
						});
				}
			} catch (err) {
				setIsSubmitting(false);
			}
		}
	};

	return (
		<Stack
			paddingX={2.5}
			paddingY={2}
			gap={1}
			flex={1}
			cy-data="compute-instance-card"
		>
			<Box
				id="compute-instance-header"
				display="flex"
				flexDirection="row"
				flexWrap="wrap"
				gap={1}
			>
				<Stack>
					<Typography
						color="text.primary"
						fontWeight={900}
						fontSize={20}
						fontFamily="Nunito Sans"
					>
						Compute
					</Typography>
					<Typography color="text.secondary" fontWeight={400} fontSize={11}>
						Manage and launch compute instances
					</Typography>
				</Stack>
			</Box>
			<Divider />
			<Box id="compute-instance-options" minHeight={175} flex={1}>
				<List
					dense
					sx={{
						pt: 0,
						maxHeight: 175,
						overflow: 'auto',
						scrollbarGutter: true
					}}
				>
					{computeProfile?.map((compute, _index) => (
						<ListItemButton
							key={compute?.display_name}
							sx={{ pl: 1, borderRadius: 1 }}
							selected={selectedProfileObj?.slug === compute?.slug}
							onClick={() => handleSelectComputeInstance(compute)}
						>
							<ListItemIcon>
								<img
									src={
										compute?.display_name.includes('GPU') ? quantum8 : quantum1
									}
									width={40}
									height={40}
									alt="qImg"
									style={{ objectFit: 'contain' }}
								/>
							</ListItemIcon>
							<ListItemText
								primaryTypographyProps={{ fontSize: 14 }}
								primary={compute?.display_name}
							/>
						</ListItemButton>
					))}
				</List>
			</Box>
			<Box
				id="compute-instance-actions"
				display="flex"
				justifyContent="flex-end"
				sx={{}}
			>
				<Button
					variant="slide"
					sx={(theme) => ({
						borderRadius: '100vw',
						fontFamily: theme.typography.fontFamily,
						fontWeight: 900,
						fontSize: 14
					})}
					onClick={handleLaunchLab}
					disabled={isSubmitting}
				>
					Launch Lab &gt;
				</Button>
			</Box>
		</Stack>
	);
}

export default ComputeInstance;
