import { Grid, Typography } from '@mui/material';
import React from 'react';

function Prizes({ backgroundImage, textContent, backgroundColor = '#000000' }) {
	return (
		<Grid
			item
			xs={12}
			sx={{
				paddingLeft: { xs: '0rem !important', sm: '4rem !important' },
				width: '100%',
				height: { xs: '400px', sm: '450px' },
				backgroundColor: backgroundColor,
				backgroundImage: `url(${backgroundImage})`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'right',
				backgroundSize: {
					xs: '150% 100%',
					sm: '125% 100%',
					md: '100% 100%',
					lg: '75% 100%',
					xl: '75% 100%'
				}
			}}
		>
			<Typography
				sx={{
					marginTop: '4rem',
					fontSize: '4rem',
					fontWeight: 500,
					paddingLeft: '1rem',
					marginBottom: '2rem',
					color: '#ffffff'
				}}
			>
				{textContent.topText}
			</Typography>
			<Typography
				sx={{
					color: '#FFFFFF',
					marginBottom: '1rem',
					fontSize: { xs: '20px', sm: '30px' },
					fontWeight: 500,
					paddingLeft: '1rem'
				}}
			>
				{textContent.middleText}
			</Typography>
			{textContent?.moreText?.length &&
				textContent.moreText.map((text) => (
					<Typography
						key={text}
						sx={{
							color: '#FFFFFF',
							marginBottom: '1rem',
							fontSize: { xs: '20px', sm: '30px' },
							fontWeight: 500,
							paddingLeft: '1rem'
						}}
					>
						{text}
					</Typography>
				))}

			<Typography
				sx={{
					color: '#FFFFFF',
					marginBottom: '1rem',
					fontSize: { xs: '20px', sm: '30px' },
					fontWeight: 500,
					paddingLeft: '1rem'
				}}
			>
				{textContent.bottomText}
			</Typography>
		</Grid>
	);
}

export default Prizes;
