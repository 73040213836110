import { ArrowUpward } from '@mui/icons-material';
import {
	Card, CardContent, CardHeader, Grid, IconButton 
} from '@mui/material';
import React, { useRef } from 'react';
import AboutBG from '../../../../../../../../assets/images/about_mitiq_bg.png';
import PrizesBG from '../../../../../../../../assets/images/prizes_mitiq_bg.png';
import ScQuantumImage from '../../../../../../../../assets/images/scQuantum.png';
import QuantumTableComponent from '../../../../../../../../components/QuantumTableComponent';
import AboutHackathon from '../../../../components/AboutHackathon';
import ChallangeCard from '../../../../components/ChallengeCard';
import Discord from '../../../../components/Discord';
import Header from '../../../../components/Header';
import Prizes from '../../../../components/Prizes';
import Rules from '../../../../components/Rules';
import VqcTable from '../VqcTable';
import { challengesData } from './chanllengesData';

function ScQuantumComponent() {
	const topRef = useRef(null);
	const aboutRef = useRef(null);
	const rulesRef = useRef(null);
	const prizesRef = useRef(null);

	const redirectTop = () => {
		topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth' });
	};

	const redirectToAbout = () => {
		aboutRef.current?.scrollIntoView({ top: 0, behavior: 'smooth' });
	};

	const redirectToRules = () => {
		rulesRef.current?.scrollIntoView({ top: 0, behavior: 'smooth' });
	};

	const redirectToPrizes = () => {
		prizesRef.current?.scrollIntoView({ top: 0, behavior: 'smooth' });
	};

	const navItems = [
		{
			title: 'About',
			clickEvent: () => {
				redirectToAbout();
			}
		},
		{
			title: 'Rules',
			clickEvent: () => {
				redirectToRules();
			}
		},
		{
			title: 'Prizes',
			clickEvent: () => {
				redirectToPrizes();
			}
		}
	];

	return (
		<Grid container spacing={2} className="iquhack-body" mb={2.25}>
			<div ref={topRef} />
			<Header
				navItems={navItems}
				headerLogo={ScQuantumImage}
				colors={{ primary: '#74b0b3', secondary: '#ffffff' }}
			/>

			<VqcTable
				tableContainerStyle={{
					borderRadius: '10px',
					backgroundImage:
						'linear-gradient(to right,rgba(255,255,255,0.3),rgba(192,219,220,0.7),rgba(43,150,211,0.7),rgba(115,176,178,0.7))',
					backgroundSize: '400% 400%',
					backgroundPosition: '10% 100%',
					overflow: 'hidden'
				}}
				textColorPrimary="#444"
			/>

			<Grid item container spacing={2} justifyContent="center">
				{challengesData.map((data) => (
					<Grid key={data.title} item display="flex" xs={12} lg={6}>
						<ChallangeCard
							data={data}
							logoSize={{ height: '30px' }}
							colors={{ primary: '#bf3c62a6', secondary: '#e2e419a6' }}
						/>
					</Grid>
				))}
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
				<Card
					className="cards-common qbraid-jobs-card"
					sx={{ marginTop: '0px' }}
					cy-data="jobs-card"
				>
					<CardHeader className="cards-header" title="Your jobs" />
					<CardContent className="cards-body">
						<QuantumTableComponent quantumType="jobs" />
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
				<Discord
					title="scquantum channel"
					source="https://discord.com/widget?id=771898982564626445&theme=dark"
					width="350"
					height="500"
				/>
			</Grid>

			<Grid
				sx={{
					scrollMargin: '4.3rem'
				}}
				ref={aboutRef}
				item
				sm={12}
				xs={12}
			>
				<AboutHackathon
					color={{ primary: '#444', secondary: '#444' }}
					image={{
						source: ScQuantumImage,
						size: { height: '200px', width: '350px' }
					}}
					description="Stay up to date on this year’s SCQuantum Quantahon in the slack channel"
				/>
			</Grid>

			<Grid
				sx={{
					scrollMargin: '4.3rem'
				}}
				ref={rulesRef}
				item
				sm={12}
				xs={12}
			>
				<Rules
					backgroundImage={AboutBG}
					textContent={{
						topText: 'Ask questions on Slack',
						middleText: 'Make sure to respect others',
						bottomText: 'Make sure to fork the main repo'
					}}
				/>
			</Grid>

			<Grid
				ref={prizesRef}
				item
				sm={12}
				xs={12}
				sx={{
					scrollMargin: '4.3rem',
					backgroundColor: 'ping',
					paddingTop: '0px !important'
				}}
			>
				<Grid container>
					<Prizes
						backgroundColor="#444"
						backgroundImage={PrizesBG}
						textContent={{
							topText: 'Launch on qBraid',
							middleText: 'Use qBraid to launch your quantum computing project',
							bottomText:
								'Click on the Github icon to view the challenge Github repository'
						}}
					/>
				</Grid>
			</Grid>

			<IconButton
				onClick={redirectTop}
				sx={{
					// color: "#FFFFFF",
					// backgroundColor: "#202225",
					backgroundColor: '#FFFFFF',
					color: '#202225',
					border: '3px solid #000000',
					padding: '12px',
					borderRadius: '50%',
					position: 'fixed',
					right: '2rem',
					bottom: '40px',
					fontSize: '3rem',
					zIndex: 99,
					cursor: 'pointer'
				}}
			>
				<ArrowUpward />
			</IconButton>
		</Grid>
	);
}

export default ScQuantumComponent;
