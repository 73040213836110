// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-text2 {
	color: #ffff !important;
	font-size: 13px !important;

	font-weight: 600 !important;
	line-height: 16px !important;
	letter-spacing: 0em !important;
	text-transform: capitalize;
	margin-left: 9px !important;
}

.filter-image2 {
	color: #ffff !important;
	filter: brightness(0) invert(1) !important;
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */

	height: 11px;
	width: 16.5px;
}

.filter-box {
	display: flex;
}
.filter-button {
	border-radius: 5px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
	padding: 8px 13px !important;
	margin-right: 6px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/hackathons/pages/HackathonEvent/2024/ScQuantum/components/VqcTable/index.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,0BAA0B;;CAE1B,2BAA2B;CAC3B,4BAA4B;CAC5B,8BAA8B;CAC9B,0BAA0B;CAC1B,2BAA2B;AAC5B;;AAEA;CACC,uBAAuB;CACvB,0CAA0C;CAC1C,+BAA+B,EAAE,qBAAqB;;CAEtD,YAAY;CACZ,aAAa;AACd;;AAEA;CACC,aAAa;AACd;AACA;CACC,6BAA6B;CAC7B,wBAAwB;CACxB,8BAA8B;CAC9B,yCAAyC;CACzC,4BAA4B;CAC5B,4BAA4B;AAC7B","sourcesContent":[".filter-text2 {\n\tcolor: #ffff !important;\n\tfont-size: 13px !important;\n\n\tfont-weight: 600 !important;\n\tline-height: 16px !important;\n\tletter-spacing: 0em !important;\n\ttext-transform: capitalize;\n\tmargin-left: 9px !important;\n}\n\n.filter-image2 {\n\tcolor: #ffff !important;\n\tfilter: brightness(0) invert(1) !important;\n\t-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */\n\n\theight: 11px;\n\twidth: 16.5px;\n}\n\n.filter-box {\n\tdisplay: flex;\n}\n.filter-button {\n\tborder-radius: 5px !important;\n\tdisplay: flex !important;\n\talign-items: center !important;\n\tjustify-content: space-between !important;\n\tpadding: 8px 13px !important;\n\tmargin-right: 6px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
