/**
 * @fileoverviewResetModal component is a child component of UserAccount component and is used to reset the password of the user.
 * @module ResetModal
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
	Dialog,
	DialogContent,
	Grid,
	TextField,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ModalDesc from '../modal-desc';
import { UserForgotPasswordFunction } from '../../../Redux/Action/userAction';
import { currentDomain } from '../../../utils/domainBasedInfo/currentDomain';
import './index.css';

export default function ResetModal({
	openReset,
	handleLogin,
	handleOpenSetPassword,
	childToParent,
	partnerAuth
}) {
	const muiTheme = useTheme();
	const validationSchema = yup.object({
		email: yup
			.string('Enter your email')
			.matches(
				/^[a-zA-Z0-9@._-]+$/,
				'An email cannot contain white spaces and special characters'
			)
			.email('Enter a valid email')
			.required('Email is required')
	});
	const formik = useFormik({
		initialValues: {
			email: ''

			// keepsigned: false,
		},
		validationSchema,
		onSubmit: async (credential) => {
			const { email } = credential;
			childToParent(email);
			dispatch(UserForgotPasswordFunction(email));
			handleOpenSetPassword();
		}
	});

	const dispatch = useDispatch();
	return (
		<Dialog
			open={openReset}
			maxWidth="fit-content"
			fullScreen={useMediaQuery(muiTheme.breakpoints.down('sm'))}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			className="login-modal"
		>
			<DialogContent sx={{ p: 0, maxWidth: 800 }}>
				<form onSubmit={formik.handleSubmit}>
					<Grid container borderRadius={2} overflow="hidden">
						<Grid
							item
							container
							lg={6}
							md={0}
							sm={0}
							xs={0}
							sx={{
								display: {
									xs: 'none',
									sm: 'none',
									md: 'none',
									lg: 'flex'
								}
							}}
							justifyContent="center"
							className="imgGrid"
						>
							<ModalDesc
								title={`Join ${
									partnerAuth ? 'QHack' : currentDomain.title
								} for free`}
								desc={
									partnerAuth
										? 'QHack is a world-renowned annual quantum hackathon that takes your education to the next level'
										: currentDomain.loginModal.description
								}
							/>
						</Grid>
						<Grid
							container
							item
							md={12}
							lg={6}
							sm={12}
							xs={12}
							sx={(theme) => ({
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '10rem 1rem',
								[theme.breakpoints.down(916)]: {
									maxHeight: '100vh',
									overflow: 'auto'
								}
							})}
						>
							<Grid item xs={12}>
								<Typography
									className="reset-text"
									textAlign="center"
									sx={{
										fontFamily: partnerAuth
											? 'Righteous !important'
											: 'Inter !important',
										color: muiTheme.palette.text.auth.login100
									}}
								>
									Reset Password
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography
									className="forget-text"
									sx={{
										fontFamily: partnerAuth
											? 'Righteous !important'
											: 'Inter !important',
										color: muiTheme.palette.text.auth.forget100
									}}
								>
									Forgot your password?
									<br /> Send a reset code to your email.
								</Typography>
							</Grid>
							<Grid container item xs={12}>
								<Typography
									className="email-text"
									sx={{
										fontFamily: partnerAuth
											? 'Righteous !important'
											: 'Inter !important',
										color: muiTheme.palette.text.auth.forget200
									}}
								>
									Enter Email Address Here
								</Typography>

								<TextField
									fullWidth
									className="input-text"
									label="name@domain.com"
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									InputLabelProps={{
										style: { color: muiTheme.palette.text.auth.signup100, fontSize: '14px' }
									}}
									error={formik.touched.email && Boolean(formik.errors.email)}
								/>
								<Button
									fullWidth
									variant="contained"
									className={
										partnerAuth ? 'xanadu-reset-button' : 'create_button'
									}
									type="submit"
									value="submit"
									sx={{
										height: 60,
										fontFamily: partnerAuth && 'Righteous !important'
									}}
								>
									Send Reset Code
								</Button>
								<Button
									fullWidth
									sx={{
										color: muiTheme.palette.text.auth.login100,
										fontSize: '12px',
										'&:hover': {
											backgroundColor: 'rgba(133, 19, 247, 0.10)',
											boxShadow: ' 0px 18px 35px rgba(152, 64, 210, 0.15)'
										},
										fontFamily: partnerAuth
											? 'Righteous !important'
											: 'Inter !important',
										mt: '1em'
									}}
									onClick={handleLogin}
									className="link-style"
								>
									Go Back
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
}
