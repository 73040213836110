import {
	JOBS_DATA_LOADING,
	JOBS_DATA_SUCCESS,
	JOBS_DATA_FAIL
} from '../Types/userJobsTypes';

import { axiosClient } from '../../utils/axiosClient';
import { apiDown } from './loadingErrMsgAction';

export const UserJobsFunction = () => async (dispatch) => {
	dispatch(UserJobsLoading());

	try {
		const result = await axiosClient().post('/api/get-user-jobs/');

		return dispatch(UserJobsSuccess(result.data));
	} catch (err) {
		if (err?.response?.status === 500) {
			dispatch(apiDown('API is down !'));
		}
		return dispatch(UserJobsFail());
	}
};

export const UserJobsLoading = () => ({
	type: JOBS_DATA_LOADING
});

export const UserJobsSuccess = (payload) => ({
	type: JOBS_DATA_SUCCESS,
	payload
});

export const UserJobsFail = () => ({
	type: JOBS_DATA_FAIL
});
