import rigettiAnaka from '../../assets/images/rigetti-anaka2.png';

export const logosData = [
	{
		provider: 'IBM',
		url: 'https://qbraid-static.s3.amazonaws.com/logos/ibmq.png',
		urlDark:
			'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/ibmq_dark.png'
	},
	{
		provider: 'Google',
		url: 'https://qbraid-static.s3.amazonaws.com/logos/google.png',
		urlDark: 'https://qbraid-static.s3.amazonaws.com/logos/google.png'
	},
	{
		provider: 'AWS',
		url: 'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-sqr/aws_light_theme.png',
		urlDark:
			'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/aws_dark.png',
		providerDescription:
			'Amazon Braket provides GPU powered simulators for quantum computing',
		about: 'https://qbraid.webflow.io/quantum-pricing/amazon-braket',
		devices: [
			{
				name: 'DM1',
				des: 'Density matrix simulator (DM1)',
				spec: [
					'DM1 is an on-demand, high-performance, density matrix simulator. It can simulate circuits of up to 17 qubits.',
					'DM1 has a maximum runtime of 6 hours, a default of 35 concurrent quantum tasks, and  a maximum of 50 concurrent quantum tasks.'
				],
				about:
					'https://docs.aws.amazon.com/braket/latest/developerguide/braket-simulator-dm1.html'
			},
			{
				name: 'SV1',
				des: 'State vector simulator (SV1)',
				spec: [
					'SV1 is an on-demand, high-performance, universal state vector simulator. It can simulate circuits of up to 34 qubits. You can expect a 34-qubit, dense, and square circuit (circuit depth = 34) to take approximately 1–2 hours to complete, depending on the type of gates used and other factors.  Circuits with all-to-all gates are well suited for SV1. It returns results  in forms such as a full state vector or an array of amplitudes.'
				],
				about:
					'https://docs.aws.amazon.com/braket/latest/developerguide/braket-simulator-sv1.html'
			},
			{
				name: 'TN1',
				des: 'Tensor network simulator (TN1)',
				spec: [
					'TN1 is an on-demand, high-performance, tensor network simulator. TN1 can simulate certain circuit types with up to 50 qubits and a circuit depth of 1,000 or smaller. TN1 is  particularly powerful for sparse circuits, circuits with local gates, and other circuits  with special structure, such as quantum Fourier transform (QFT) circuits. TN1 operates in two phases. First, the rehearsal phase attempts to identify an efficient computational path for your circuit so TN1 can estimate the runtime of the next stage, which is called the contraction phase. If the estimated contraction time exceeds the TN1 simulation runtime limit, TN1 does not attempt contraction. TN1 has a runtime limit of 6 hours. It is limited to a maximum of 10 (5 in eu-west-2) concurrent quantum tasks.'
				],
				about:
					'https://docs.aws.amazon.com/braket/latest/developerguide/braket-simulator-tn1.html'
			}
		]
	},
	{
		provider: 'D-WAVE',
		url: 'https://qbraid-static.s3.amazonaws.com/logos/dwave.png',
		urlDark: 'https://qbraid-static.s3.amazonaws.com/logos/dwave.png'
	},
	{
		provider: 'IonQ',
		url: 'https://qbraid-static.s3.amazonaws.com/logos/ionq.png',
		urlDark:
			'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/ionq_dark.png',
		devices: [
			{
				name: 'Harmony',
				des: '11 qubit quantum computer',
				spec: [
					"With Harmony's configurable error mitigation, #AQ 9 means you can successfully run quantum algorithms of ~100 entangling gates on up to 9 qubits. Credit IonQ"
				],
				about: 'https://ionq.com/quantum-systems/harmony',
				deviceImage:
					'https://cdn.prod.website-files.com/6612e81296b0f15f80a68313/66233b1befa66c07a8782318_quantum%203.png'
			},
			{
				name: 'Forte-1',
				des: '36 qubit quantum computer',
				spec: [
					"With Forte's configurable error mitigation, #AQ 35 would mean you could successfully run quantum algorithms of ~980 entangling gates on up to 35 qubits. Credit IonQ"
				],
				about: 'https://ionq.com/quantum-systems/forte',
				deviceImage:
					'https://cdn.prod.website-files.com/6612e81296b0f15f80a68313/66233b1befa66c07a8782318_quantum%203.png'
			},
			{
				name: 'Aria-1',
				des: '25 qubit quantum computer',
				spec: [
					"With Aria's configurable error mitigation, #AQ 25 would mean you could successfully run quantum algorithms of ~600 entangling gates on up to 25 qubits. Credit IonQ"
				],
				about: 'https://ionq.com/quantum-systems/aria',
				deviceImage:
					'https://cdn.prod.website-files.com/6612e81296b0f15f80a68313/66233b1befa66c07a8782318_quantum%203.png'
			},
			{
				name: 'Aria-2',
				des: '25 qubit quantum computer',
				spec: [
					"With Aria's configurable error mitigation, #AQ 25 would mean you could successfully run quantum algorithms of ~600 entangling gates on up to 25 qubits. Credit IonQ"
				],
				about: 'https://ionq.com/quantum-systems/aria',
				deviceImage:
					'https://cdn.prod.website-files.com/6612e81296b0f15f80a68313/66233b1befa66c07a8782318_quantum%203.png'
			}
		]
	},
	{
		provider: 'OQC',
		url: 'https://qbraid-static.s3.amazonaws.com/logos/oqc.png',
		urlDark:
			'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/oqc_dark.png',

		about: 'https://qbraid.webflow.io/quantum-pricing/oxford-quantum-circuits',
		devices: [
			{
				name: 'Lucy',
				des: '8-qubit quantum computer',
				spec: [
					'OQC’s system, Lucy, is an 8-qubit quantum computer. It is named after Lucy Mensing, a German physicist and a pioneer of quantum mechanics. OQC’s core technology innovation – the Coaxmon – is a qubit developed from first principles with commercial scalability in mind. '
				],
				about: 'https://aws.amazon.com/braket/quantum-computers/oqc/',
				deviceImage:
					'https://cdn.prod.website-files.com/6612e81296b0f15f80a68313/664cad03cf47eac940272921_Untitled%20design(9).png'
			}
		]
	},
	{
		provider: 'QuEra',
		url: 'https://qbraid-static.s3.amazonaws.com/logos/quera.png',
		urlDark:
			'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/quera_dark.png',
		deviceImage:
			'https://cdn.prod.website-files.com/6612e81296b0f15f80a68313/66233ae2c3d16f2cc9975b41_quantum%202.png',
		providerDescription:
			'QuEra Computing develops neutral atom quantum computers, a highly promising quantum modality.',
		about: 'https://qbraid.webflow.io/quantum-pricing/quera',
		devices: [
			{
				name: 'Aquila',
				des: '256 neutral atom analog quantum computer',
				about: 'https://www.quera.com/aquila'
			}
		]
	},
	{
		provider: 'Rigetti',
		url: 'https://qbraid-static.s3.amazonaws.com/logos/rigetti.png',
		urlDark:
			'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/rigetti_dark.png',
		providerDescription:
			'Rigetti boasts hybrid cloud, public, and private quantum computing integrations through their Quantum Cloud Services.',
		about: 'https://qbraid.webflow.io/quantum-pricing/rigetti',
		devices: [
			{
				name: 'Aspen-M-3',
				des: 'Based on Rigetti’s latest 32-qubit node technology',
				spec: [
					'Fast (60ns and 160ns) 1Q and 2Q gates',
					'32 qubits',
					'Coherence times measuring ~20µs'
				],
				about: 'https://aws.amazon.com/braket/quantum-computers/rigetti/',
				deviceImage:
					'https://cdn.prod.website-files.com/6612e81296b0f15f80a68313/66233aa8e8288ee065572204_quantum%201.png'
			},
			{
				name: 'Ankaa-2',
				des: '84-qubit processor based on scalable multi-chip technology and features enhanced readout capabilities that contribute to better overall circuit fidelities independent of depth and width. Credit: https://qcs.rigetti.com/qpus',
				spec: [
					'Median T1	12 µs Median T2	13 µs',
					'Median f1QRB	99.85% ± 0.01% Median f1Q sim. RB	99.37% ± 0.13%',
					'Median fCZ	95.54% Median fISWAP	97.00%',
					'Median fRO	94.70%'
				],
				about: 'https://qcs.rigetti.com/qpus',
				deviceImage: rigettiAnaka
			}
		]
	},
	{
		provider: 'Xanadu',
		url: 'https://qbraid-static.s3.amazonaws.com/logos/xanadu-tall.png',
		urlDark: 'https://qbraid-static.s3.amazonaws.com/logos/xanadu_tall_dark.png'
	},
	{
		provider: 'qBraid',
		url: 'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/qbraid.png',
		urlDark:
			'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/qbraid_dark.png'
	},
	{
		// IQM GARNET
		provider: 'IQM',
		url: 'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/iqm.png',
		urlDark:
			'https://qbraid-static.s3.amazonaws.com/lab-docs/logos-full/iqm_dark.png',
		providerDescription:
			'IQM Garnet is a high-fidelity 20-qubit QPU based on superconducting transmon qubits',
		about: 'https://qbraid.webflow.io/quantum-pricing/iqm',
		devices: [
			{
				name: 'Garnet',
				des: '20 qubit universal gate-model superconducting QPU',
				spec: [
					'IQM Garnet is a high-fidelity 20-qubit QPU based on superconducting transmon qubits. The qubits are arranged in a square lattice and connected by tunable couplers. The system is calibrated to support Phase Rx as the native single-qubit gate and CZ as the native two-qubit gate. IQM Garnet contains 20 computational transmon qubits, 30 tunable coupler qubits, and a square lattice topology enabling high connectivity.'
				],
				about: 'https://www.iqmacademy.com/qpu/garnet/',
				deviceImage:
			'https://cdn.prod.website-files.com/6612e81296b0f15f80a68313/66511d7f3ccc1b4cecb99e65_Untitled%20design%20(1).png',
			}
		]
	}
];

export const fallBackLogo =
	'https://qbraid-static.s3.amazonaws.com/logos/qbraid.png';
