import {
	Button, Grid, InputAdornment, Stack, Typography 
} from '@mui/material';
import React, { useState } from 'react';
import GithubLogo from '../../../../../../../../assets/images/github_new.png';
import ionq from '../../../../../../../../assets/images/ionq.png';
import QbraidWhiteLogo from '../../../../../../../../assets/images/qbraid_white_logo.png';
import useFetch from '../../../../../../../../hooks/useFetch';
import ProgressGraph from '../ProgressGraph';
import TeamTable from '../TeamTable';
import './index.css';

function VqcTable({ tableContainerStyle, textColorPrimary }) {
	const [selectedRow, setSelectedRow] = useState({});

	const {
		data: teamsData,
		error,
		isLoading,
		refresh
	} = useFetch('/api/scquantum/ionq/team/submissions');

	const handleLaunchButtonClick = () =>
		window
			.open(
				'https://account.qbraid.com/?gitHubUrl=https://github.com/iQuHACK/2024_Ionq_Remote.git',
				'_blank',
				'noreferrer'
			)
			.focus();

	const handleTableRowClick = (row) => {
		if (selectedRow.id === row.id) {
			setSelectedRow({});
			return;
		}
		setSelectedRow(row);
	};

	if (error) {
		return null;
	}

	return (
		<Grid item container sx={tableContainerStyle}>
			<Grid
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%'
				}}
				item
				xs={12}
				container
			>
				<Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
					<Stack
						sx={{
							userSelect: 'none',
							padding: '2px 5px',
							cursor: 'pointer',
							borderRadius: '4px',
							display: 'flex',
							justifyContent: 'center',
							position: 'relative',
							'&:before': {
								content: '""',
								backgroundImage:
									'linear-gradient(153.68deg, #74b0b3 0%, #ffffff 175%)',
								width: '120%',
								height: '150%',
								position: 'absolute',
								top: -15,
								left: -25,
								borderRadius: 2,
								transform: 'skew(-10deg)'
							}
						}}
						direction="row"
						alignItems="center"
						gap={1}
					>
						<img
							src={ionq}
							alt=""
							style={{
								float: 'left',
								width: '60px',
								zIndex: 1
							}}
						/>

						<Typography
							variant="h5"
							sx={{
								color: '#FFFFFF',
								fontSize: '24px',
								fontWeight: 600,
								float: 'left',
								lineHeight: '2rem',
								zIndex: 1
							}}
						>
							South Carolina Quantum Challenge
						</Typography>
						<a
							href="https://github.com/iQuHACK/2024_Ionq_Remote"
							target="_blank"
							rel="noopener noreferrer"
							style={{
								zIndex: 1
							}}
						>
							<img
								src={GithubLogo}
								alt="github"
								style={{
									float: 'right',
									width: '25px'
								}}
							/>
						</a>
					</Stack>
				</Grid>

				<Grid
					item
					xs={12}
					sm={4}
					md={4}
					lg={3}
					xl={3}
					sx={{
						margin: '10px 30px 0px 0px',
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						gap: '8px'
					}}
					spacing={1}
				>
					<Button variant="contained" sx={{ fontSize: 14 }} onClick={refresh}>
						Refresh
					</Button>
					<Button
						onClick={handleLaunchButtonClick}
						variant="contained"
						title="Open in new window"
						sx={{ fontSize: 14 }}
						endIcon={
							<InputAdornment>
								<img
									src={QbraidWhiteLogo}
									alt=""
									style={{
										width: '70px'
									}}
								/>
							</InputAdornment>
						}
					>
						Launch on
					</Button>
				</Grid>
			</Grid>
			{!isLoading && teamsData && (
				<Grid
					item
					xs={12}
					sx={{
						margin: '20px 30px',
						borderRadius: '5px',
						background: 'transparent',
						width: { xs: '80%', sm: '100%' }
					}}
				>
					<ProgressGraph
						tableRows={teamsData?.teams}
						selectedRow={selectedRow}
					/>
				</Grid>
			)}
			<Grid
				item
				xs={12}
				sx={{
					margin: '20px 30px',
					borderRadius: '5px',
					background: 'rgba(217, 217, 217, 0.16)',
					width: {
						xs: '80%'
					}
				}}
			>
				<TeamTable
					handleTableRowClick={handleTableRowClick}
					isLoading={isLoading}
					selectedRow={selectedRow}
					tableRows={teamsData?.teams}
					textColorPrimary={textColorPrimary}
				/>
			</Grid>
		</Grid>
	);
}

export default VqcTable;
