import React from 'react';
import { Grid, Typography } from '@mui/material';

function Rules({ backgroundImage, textContent }) {
	return (
		<Grid
			container
			spacing={0}
			sx={{
				display: 'flex',
				flexDirection: { xs: 'row', sm: 'row-reverse' },
				paddingLeft: '2rem',
				width: '100%',
				height: { xs: '450px', sm: '400px' },
				backgroundImage: `url(${backgroundImage})`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'right',
				backgroundSize: {
					xs: '150% 100%',
					sm: '125% 100%',
					md: '100% 100%',
					lg: '75% 100%',
					xl: '75% 100%'
				}
			}}
		>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={4}
				xl={4}
				sx={{
					display: 'flex',
					justifyContent: {
						xl: 'flex-start',
						lg: 'flex-start',
						md: 'center',
						sm: 'center'
					},
					alignItems: 'center'
				}}
			>
				<Typography
					sx={(theme) => ({
						fontSize: '5rem',
						fontWeight: 500,
						paddingLeft: { xs: '0rem', sm: '1rem' },
						marginBottom: {
							xs: '0rem',
							sm: '0rem',
							md: '0rem',
							lg: '2rem',
							xl: '2rem'
						},
						background: `linear-gradient(97.75deg, ${theme.palette.text.disabled} 0.9%, ${theme.palette.text.primary} 25.00%)`,
						WebkitBackgroundClip: 'text',
						WebkitTextFillColor: 'transparent'
					})}
				>
					RULES
				</Typography>
			</Grid>

			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={8}
				xl={8}
				sx={{
					textAlign: {
						xs: 'start',
						sm: 'center',
						md: 'center',
						lg: 'start',
						xl: 'start'
					},
					marginTop: {
						xs: '0rem',
						sm: '0rem',
						md: '0rem',
						lg: '6rem',
						xl: '6rem'
					}
				}}
			>
				<Typography
					color="text.primary"
					sx={{
						fontSize: {
							xs: '24px',
							sm: '28px',
							md: '30px',
							lg: '32px',
							xl: '36px'
						},
						paddingLeft: {
							xs: '0rem',
							sm: '0rem',
							md: '0rem',
							lg: '9rem',
							xl: '9rem'
						},
						fontWeight: 500
					}}
				>
					{textContent.topText}
				</Typography>

				<Typography
					color="text.primary"
					sx={{
						fontSize: {
							xs: '24px',
							sm: '28px',
							md: '30px',
							lg: '32px',
							xl: '36px'
						},
						paddingLeft: {
							xs: '0rem',
							sm: '0rem',
							md: '0rem',
							lg: '5rem',
							xl: '5rem'
						},
						fontWeight: 500
					}}
				>
					{textContent.middleText}
				</Typography>

				<Typography
					color="text.primary"
					sx={{
						fontSize: {
							xs: '24px',
							sm: '28px',
							md: '30px',
							lg: '32px',
							xl: '36px'
						},
						paddingLeft: {
							xs: '0rem',
							sm: '0rem',
							md: '0rem',
							lg: '0rem',
							xl: '0rem'
						},
						fontWeight: 500
					}}
				>
					{textContent.bottomText}
				</Typography>
			</Grid>
		</Grid>
	);
}

export default Rules;
